<template>
  <div>
    <div style="height: 2px !important; margin-bottom: 14px">
      <v-progress-linear
        :indeterminate="loading"
        :color="loading ? 'primary' : 'grey lighten-2'"
        :value="!loading ? 100 : 0"
      ></v-progress-linear>
    </div>

    <!-- Header -->
    <div class="px-5 mt-5 d-flex align-center">
      <div>
        <div class="text-h4 primary--text d-flex">Uploader</div>
      </div>
    </div>

    <v-row class="px-5 pt-5">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8">
        <BasicCard>
          <div class="pa-5">
            <div
              class="d-flex align-left flex-column"
              :class="$vuetify.breakpoint.width <= 600 ? 'd-flex flex-column mr-4' : ''"
            >
              <div
                class="text-h5 secondary--text pb-4"
                :class="$vuetify.breakpoint.width <= 600 ? 'px-4' : 'ml-6'"
                :style="$vuetify.breakpoint.width <= 600 ? 'width:100%;' : ''"
              >
                Upload File <v-icon>mdi-file-upload</v-icon>
              </div>

              <div class="ml-6 grey--text body-2">
                <p>
                  Please upload files in CSV format. The system will process and store the data
                  automatically.
                </p>
                <p v-if="uploadLimits" class="mt-2">
                  Maximum file size: {{ uploadLimits.maxSize }}MB
                </p>
              </div>

              <!-- File Input -->
              <div class="mx-6 mt-4">
                <v-file-input
                  :density="density"
                  v-model="files"
                  label="Select a CSV file"
                  prepend-icon="mdi-file-csv"
                  outlined
                  clearable
                  accept=".csv"
                  :disabled="loading"
                  :error-messages="fileErrors"
                  @change="validateFile"
                  hint="Only CSV files are supported"
                  persistent-hint
                ></v-file-input>

                <div class="d-flex justify-space-between mt-6">
                  <v-btn
                    color="primary"
                    :disabled="!isValidFile || loading"
                    @click="uploadFile"
                    class="px-6"
                  >
                    <v-icon left>mdi-cloud-upload</v-icon>
                    Upload
                  </v-btn>

                  <v-btn text color="grey darken-1" :disabled="loading" @click="resetForm">
                    Cancel
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </BasicCard>
      </v-col>

      <!-- Info Card -->
      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
        <BasicCard>
          <div class="pa-5">
            <div class="text-h6 secondary--text mb-4">Upload Guidelines</div>
            <v-divider class="mb-4"></v-divider>

            <div class="grey--text text--darken-1 body-2">
              <p>
                <v-icon small color="primary" class="mr-2">mdi-information-outline</v-icon>Files
                must be in CSV format
              </p>
              <p>
                <v-icon small color="primary" class="mr-2">mdi-information-outline</v-icon>Each CSV
                should follow the required structure
              </p>
              <p>
                <v-icon small color="primary" class="mr-2">mdi-information-outline</v-icon>Ensure
                data is clean and properly formatted
              </p>
              <p>
                <v-icon small color="primary" class="mr-2">mdi-information-outline</v-icon>Large
                files may take longer to process
              </p>
            </div>

            <v-divider class="my-4"></v-divider>

            <div class="text-h6 secondary--text mb-4">Recent Uploads</div>
            <div v-if="recentUploads.length === 0" class="grey--text body-2">No recent uploads</div>

            <v-list dense v-else>
              <v-list-item v-for="(upload, index) in recentUploads" :key="index">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-file-document-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ upload.filename }}</v-list-item-title>
                  <v-list-item-subtitle>{{ upload.date }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon :color="upload.status === 'success' ? 'success' : 'error'">
                    {{ upload.status === "success" ? "mdi-check-circle" : "mdi-alert-circle" }}
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </BasicCard>
      </v-col>
    </v-row>

    <!-- Upload Status Dialog -->
    <v-dialog v-model="showStatusDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ uploadStatus.success ? "Upload Successful" : "Upload Failed" }}
        </v-card-title>

        <v-card-text>
          <div v-if="uploadStatus.success">
            <v-alert type="success" text dense>
              Your file has been uploaded and processed successfully.
            </v-alert>
            <div class="mt-4">
              <p><strong>Filename:</strong> {{ uploadStatus.filename }}</p>
              <p><strong>Records processed:</strong> {{ uploadStatus.recordsProcessed }}</p>
              <p><strong>Date:</strong> {{ uploadStatus.date }}</p>
            </div>
          </div>

          <div v-else>
            <v-alert type="error" text dense> There was an error processing your file. </v-alert>
            <div class="mt-4">
              <p><strong>Error:</strong> {{ uploadStatus.error }}</p>
              <p>Please check your file format and try again.</p>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeStatusDialog"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "UploaderView",
  data() {
    return {
      loading: false,
      density: "default",
      files: [],
      fileErrors: [],
      isValidFile: false,
      uploadLimits: {
        maxSize: 10, // MB
        allowedTypes: [".csv"],
      },
      showStatusDialog: false,
      uploadStatus: {
        success: false,
        filename: "",
        recordsProcessed: 0,
        date: "",
        error: "",
      },
      recentUploads: [
        // Placeholder data - would typically come from an API
        /*
        {
          filename: "business_data_2023.csv",
          date: "2023-09-05 13:45",
          status: "success"
        }
        */
      ],
    };
  },

  methods: {
    validateFile() {
      this.fileErrors = [];
      this.isValidFile = false;

      if (!this.files || this.files.length === 0) {
        return;
      }

      const file = this.files;

      // Check file type
      if (!file.name.endsWith(".csv")) {
        this.fileErrors.push("Only CSV files are allowed");
        return;
      }

      // Check file size
      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB > this.uploadLimits.maxSize) {
        this.fileErrors.push(
          `File size exceeds the maximum limit of ${this.uploadLimits.maxSize}MB`
        );
        return;
      }

      this.isValidFile = true;
    },

    async uploadFile() {
      if (!this.isValidFile) return;

      this.loading = true;

      try {
        // Use REDIS_uploadCSV method from mixin
        const result = await this.REDIS_uploadCSV(this.files);

        this.uploadStatus = {
          success: result.success,
          filename: this.files.name,
          recordsProcessed: result?.data?.length || 0,
          date: new Date().toLocaleString(),
          error: result.error || "",
        };

        if (result.success) {
          // Add to recent uploads if successful
          this.recentUploads.unshift({
            filename: this.files.name,
            date: new Date().toLocaleString(),
            status: "success",
          });

          // Keep only the last 5 recent uploads
          if (this.recentUploads.length > 5) {
            this.recentUploads.pop();
          }
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        this.uploadStatus = {
          success: false,
          filename: this.files.name,
          recordsProcessed: 0,
          date: new Date().toLocaleString(),
          error: "Upload failed. Please try again.",
        };
      } finally {
        this.loading = false;
        this.showStatusDialog = true;
      }
    },
    resetForm() {
      this.files = [];
      this.fileErrors = [];
      this.isValidFile = false;
    },

    closeStatusDialog() {
      this.showStatusDialog = false;
      if (this.uploadStatus.success) {
        this.resetForm();
      }
    },
  },

  created() {
    // This would typically fetch recent uploads from an API
    // For demo purposes, we'll leave it empty or could add sample data
  },
};
</script>

<style scoped>
:deep(.v-input .v-label) {
  color: grey !important;
}

.v-file-input :deep(.v-file-input__text) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-btn:not(.v-btn--text) {
  box-shadow: none !important;
}
</style>
