const user = {
	firstName: "",
	lastName: "",
	jobRole: "",
	telephone: "",
	email: "",
	orgId: "",
	status: "",
	group: "",
	level: "",
	orgType: "",
	termsAgreed: false,
	optInAgreed: false,
	createdUserId: "",
	createdDateTime: "",
	modifiedUserId: "",
	modifiedDateTime: "",
	deletedUserId: "",
	deletedDateTime: "",
	deleted: false,
}

const org = {
	id: "",
	name: "",
	registeredName: "",
	registrationNumber: "",
	description: "",
	contactFirstName: "",
	contactLastName: "",
	contactJobRole: "",
	telephone: "",
	email: "",
	website: "",
	addressLine1: "",
	addressLine2: "",
	addressLine3: "",
	town: "",
	county: "",
	postcode: "",
	country: "",
	latitude: "",
	longitude: "",
	type: [],
	pipeline: "",
	stage: "",
	source: "",
	sourceNotes: "",
	accountNumber: "",
	socialMedia: [],
	turnover: 0,
	employees: 0,
	matchCount: 0,
	tags: [],
	imageIds: [],
	status: "",
	parentOrgId: "",
	engaged: "",
	agreement: "",
	ndaStatus: "",
	string1: "",
	string2: "",
	string3: "",
	string4: "",
	string5: "",
	text1: "",
	text2: "",
	text3: "",
	text4: "",
	text5: "",
	ownerUserId: "",
	createdUserId: "",
	createdDateTime: "",
	modifiedUserId: "",
	modifiedDateTime: "",
	deletedUserId: "",
	deletedDateTime: "",
	deleted: false,
}

const orgprofile = {
	orgId: "",
	orgName: "",
	description: "",
	type: [],
	inSectors: [],
	exSectors: [],
	inSubSectors: [],
	exSubSectors: [],
	inKeywords: [],
	exKeywords: [],
	inRegion: [],
	exRegion: [],
	inCountry: [],
	exCountry: [],
	inOwnership: [],
	exOwnership: [],
	priceMin: 0,
	priceMax: 0,
	turnoverMin: 0,
	turnoverMax: 0,
	ebitdaMin: 0,
	ebitdaMax: 0,
	profitMin: 0,
	profitMax: 0,
	weights: "",
	enabled: true,
	ownerUserId: "",
	createdUserId: "",
	createdDateTime: "",
	modifiedUserId: "",
	modifiedDateTime: "",
	deletedUserId: "",
	deletedDateTime: "",
	deleted: false,
}

const lookup = {
	text: "",
	value: "",
	type: "",
	filter: "",
	deleted: false,
}

const source = {
	name: "",
	description: "",
	orgId: "",
	website: "",
	username: "",
	password: "",
	contactName: "",
	contactEmail: "",
	contactTelephone: "",
	lastFeedDateTime: null,
	status: "",
	enabled: true,
	createdUserId: "",
	createdDateTime: null,
	modifiedUserId: "",
	modifiedDateTime: null,
	deletedUserId: "",
	deletedDateTime: null,
	deleted: false,
}

const business = {
	id: "",
	title: "",
	summary: "",
	description: "",
	points: [],
	text: "",
	ref: "",
	location: [],
	city: [],
	county: [],
	region: [],
	country: [],
	turnover: 0,
	price: 0,
	profit: 0,
	ebitda: 0,
	currency: "GBP",
	financialYear: "",
	sourceId: "",
	sourceName: "",
	sourceURL: "",
	sectors: [],
	subSectors: [],
	keywords: [],
	ownership: "",
	type: "",
	status: "",
	engaged: "",
	agreement: "",
	string1: "",
	string2: "",
	string3: "",
	string4: "",
	string5: "",
	text1: "",
	text2: "",
	text3: "",
	text4: "",
	text5: "",
	listingDateTime: "",
	lastSeenDateTime: "",
	createdUserId: "",
	createdDateTime: "",
	modifiedUserId: "",
	modifiedDateTime: "",
	deletedUserId: "",
	deletedDateTime: "",
	deleted: false,
}

const match = {
	id: "",
	status: "",
	orgId: "",
	orgName: "",
	businessId: "",
	businessTitle: "",
	businessType: "",
	matchPercent: null,
	matchReport: "",
	enquiryStatus: "",
	reason: "",
	reasonNotes: "",
	createdUserId: "",
	createdDateTime: null,
	modifiedUserId: "",
	modifiedDateTime: null,
	deletedUserId: "",
	deletedDateTime: null,
	deleted: false,
}

const stat = {
	id: "",
	type: "",
	rating: 0,
	category: "",
	subcategory: "",
	linkedId: "",
	createdUserId: "",
	createdDateTime: "",
	modifiedUserId: "",
	modifiedDateTime: "",
	deletedUserId: "",
	deletedDateTime: "",
	deleted: false,
}

const note = {
	orgId: "",
	orgName: "",
	businessId: "",
	matchId: "",
	message: "",
	relatedSection: "",
	createdName: "",
	createdUserId: "",
	createdDateTime: "",
	modifiedUserId: "",
	modifiedDateTime: "",
	deletedUserId: "",
	deletedDateTime: "",
	deleted: false,
}

const mail = {
	toEmail: "",
	toName: "",
	data: [],
	fromEmail: "",
	fromName: "",
	toOrgId: "",
	subject: "",
	templateId: "",
	customMessage: "",
	readDateTime: "",
	createdName: "",
	createdUserId: "",
	createdDateTime: "",
	modifiedUserId: "",
	modifiedDateTime: "",
	deletedUserId: "",
	deletedDateTime: "",
	deleted: false,
}

module.exports = {
	user,
	org,
	lookup,
	stat,
	// GS Verde specific
	source,
	orgprofile,
	business,
	match,
	note,
	mail
};
