<template>
    <div class="grey lighten-2" no-gutters>
        <v-bottom-navigation v-if="showDrawer" :height="$vuetify.breakpoint.xsOnly ? '100' :'90'" class="fixed rounded-of-top pa-5 foreground-high" color="primary" horizontal :ripple="false">
            <div class="d-flex justify-center gap-2 w-100">
                <v-btn :disabled="MIX_checkLevelAndStatus(item)" @click="MIX_go(item.path)" v-for="(item, index) in MIX_filteredNav('BottomNav')" :key="index" class="white pa-4">
                    <div class="d-flex flex-column">
                        <v-icon x-large class="pt-4">{{ item.icon }}</v-icon>
                        <!-- <div class=" text-body-2 font-weight-bold mt-1">{{ item.title }}</div> -->
                    </div>
                </v-btn>
            </div>
        </v-bottom-navigation>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "BottomNav",
    data: () => ({ 
        showDrawer: false }),
    // * METHODS
    METHODS: {
        ...mapActions({
            ACT_showDrawer: "ACT_showDrawer",
        }),
    },
    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
            GET_userAuthenticated: "GET_userAuthenticated",
            GET_showDrawer: "GET_showDrawer",
        }),
    },
    // * MOUNTED
    mounted() {
        if (window.innerWidth < 880 && window.innerHeight > 600) {
            this.showDrawer = true;
        }
        // console.log(this.GET_currentUserProfile.orgType)
    },
    // * WATCH
    watch: {
        // GET_showDrawer: {
        //     this.showDrawer = this.GET_showDrawer;
        // },
           GET_showDrawer: {
            handler() {
                this.showDrawer = this.GET_showDrawer;
            },
            deep: true,
        },
        // showDrawer: function () {
        //     this.ACT_showDrawer(this.showDrawer);
        // },
    },
    created() {
        // console.log(this.GET_currentUserProfile.orgType)
    },
};
</script>
