<template>
  <div>
    <v-row class="px-5 pt-5" :key="rowKey">
      <v-col cols="12">
        <div class="text-h4 primary--text">Match Cleanup</div>
        <div class="body-2 grey--text text--darken-2 pt-2">
          This utility will remove matches for businesses that are no longer in the system.
        </div>
      </v-col>
      
      <!-- Match Statistics Card -->
      <v-col cols="12" sm="6" md="4">
        <v-card class="pa-4">
          <div class="text-h6 primary--text mb-4">Match Statistics</div>
          <div class="d-flex flex-column">
            <div class="d-flex justify-space-between align-center mb-2">
              <span>Total Matches:</span>
              <span class="font-weight-bold">{{ totalMatches }}</span>
            </div>
            <div class="d-flex justify-space-between align-center mb-4">
              <span>Invalid Matches:</span>
              <span class="font-weight-bold error--text">{{ invalidMatches.length }}</span>
            </div>
            
            <v-btn 
              class="primary white--text" 
              :loading="scanning" 
              :disabled="scanning" 
              @click="scanMatches"
            >
              <v-icon left>icons8-synchronize</v-icon>
              Scan Matches
            </v-btn>
            
            <v-btn 
              class="mt-4 error white--text" 
              :loading="cleaning" 
              :disabled="cleaning || invalidMatches.length === 0 || !scanned" 
              @click="cleanupConfirmation"
            >
              <v-icon left>icons8-trash-can</v-icon>
              Remove Invalid Matches
            </v-btn>
          </div>
        </v-card>
      </v-col>
      
      <v-col cols="12" sm="6" md="8">
        <v-card v-if="invalidMatches.length > 0" class="pa-4">
          <div class="text-h6 primary--text mb-4">Invalid Matches ({{ invalidMatches.length }})</div>
          <v-data-table
            :headers="headers"
            :items="processedInvalidMatches"
            :items-per-page="10"
            item-key="uniqueKey"
            class="elevation-1"
          >
            <template #[`item.status`]="{ item }">
              <v-chip small :color="getStatusColor(item.status)" text-color="white">
                {{ item.status }}
              </v-chip>
            </template>
            <template #[`item.createdDateTime`]="{ item }">
              {{ MIX_formatDateTime(item.createdDateTime, "ISOString", "D MMM YYYY") }}
            </template>
          </v-data-table>
        </v-card>
        
        <v-card v-else-if="scanning" class="pa-4 d-flex justify-center align-center" style="min-height: 200px">
          <div class="text-center">
            <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
            <div class="mt-4 text-body-1">Scanning matches...</div>
          </div>
        </v-card>
        
        <v-card v-else class="pa-4 d-flex justify-center align-center" style="min-height: 200px">
          <div class="text-center">
            <v-icon size="64" color="grey lighten-1">icons8-check-circle</v-icon>
            <div class="mt-4 text-body-1 grey--text text--darken-1">
              {{ scanned ? "No invalid matches found" : "Click 'Scan Matches' to begin" }}
            </div>
          </div>
        </v-card>
      </v-col>
      
      <v-col cols="12" v-if="cleanupComplete">
        <v-alert type="success" dismissible>
          Successfully removed {{ lastCleanupCount }} invalid matches from the system.
        </v-alert>
      </v-col>
    </v-row>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="showConfirmation" max-width="500px">
      <v-card>
        <v-card-title class="primary white--text">
          Confirm Removal
        </v-card-title>
        <v-card-text class="pt-4">
          <p>Are you sure you want to remove {{ invalidMatches.length }} invalid matches?</p>
          <p class="warning--text">This action cannot be undone. It will mark these matches as deleted in the database.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="showConfirmation = false">Cancel</v-btn>
          <v-btn color="error" text @click="cleanupMatches">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Progress Dialog -->
    <v-dialog v-model="showProgressDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          Cleanup Progress
        </v-card-title>
        <v-card-text class="pt-4 text-center">
          <v-progress-circular indeterminate color="primary" size="64" class="mb-3"></v-progress-circular>
          <p>{{ progressMessage }}</p>
          <p class="mt-2">{{ currentProgress }} / {{ totalProgress }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MatchCleanup",
 data: () => ({
  totalMatches: 0,
  invalidMatches: [],
  businessIds: new Set(),
  scanning: false,
  cleaning: false,
  scanned: false,
  cleanupComplete: false,
  lastCleanupCount: 0,
  showConfirmation: false,
  showProgressDialog: false,
  progressMessage: "Processing matches...",
  currentProgress: 0,
  totalProgress: 0,
  headers: [
    { text: "Match ID", value: "id", sortable: true },
    { text: "Business Title", value: "businessTitle", sortable: true },
    { text: "Business ID", value: "businessId", sortable: true },
    { text: "Organization", value: "orgName", sortable: true },
    { text: "Status", value: "status", sortable: true },
    { text: "Created Date", value: "createdDateTime", sortable: true }
  ],
  rowKey: 0  // Add a unique key to trigger re-render
}),
  computed: {
    ...mapGetters({
      GET_currentUserProfile: "GET_currentUserProfile"
    }),
    
    // Create processed matches with guaranteed unique keys
    processedInvalidMatches() {
      return this.invalidMatches.map((match, index) => ({
        ...match,
        uniqueKey: `invalid-${match.id}-${index}`
      }));
    }
  },
  methods: {
    getStatusColor(status) {
      switch (status?.toLowerCase()) {
        case 'accepted':
          return 'success';
        case 'rejected':
          return 'error';
        case 'pending':
          return 'warning';
        default:
          return 'grey';
      }
    },
    
    cleanupConfirmation() {
      this.showConfirmation = true;
    },
    
    // Get all business IDs to check against
    async getAllBusinessIds() {
      // Clear the set first
      this.businessIds.clear();
      
      try {
        // Get all business IDs - we may need to paginate if there are many businesses
        let offset = 0;
        const limit = 1000; // Process in batches
        let hasMore = true;
        
        while (hasMore) {
          const businessResult = await this.REDIS_searchFor(
            "business", 
            offset, 
            limit, 
            "id", 
            "asc", 
            "@deleted:{false}"
          );
          
          const businesses = businessResult.data.documents;
          
          // Add all business IDs to the set
          businesses.forEach(business => {
            this.businessIds.add(business.id);
          });
          
          // Check if we need to fetch more
          offset += limit;
          hasMore = businesses.length === limit && offset < businessResult.data.total;
        }
        
        console.log(`Retrieved ${this.businessIds.size} business IDs`);
        return true;
      } catch (error) {
        console.error("Error getting business IDs:", error);
        return false;
      }
    },
    
    // Find invalid matches (businesses that no longer exist)
    findInvalidMatches(activeMatches) {
      console.log("Finding matches with invalid businesses");
      
      const invalidMatches = [];
      
      // Find matches where the business ID doesn't exist
      for (const match of activeMatches) {
        // Check if business exists
        const isBusinessValid = this.businessIds.has(match.businessId);
        
        // If business is invalid, add to invalid matches
        if (!isBusinessValid) {
          invalidMatches.push(match);
        }
      }
      
      return invalidMatches;
    },
    
    // Main scan method
    async scanMatches() {
      if (this.scanning) return;
      
      try {
        this.scanning = true;
        this.scanned = false;
        this.cleanupComplete = false;
        this.invalidMatches = [];
        
        // Get all business IDs
        const businessIdsResult = await this.getAllBusinessIds();
        if (!businessIdsResult) {
          throw new Error("Failed to get business IDs");
        }
        
        // Get all matches
        console.log("Searching for matches...");
        const matchResult = await this.REDIS_searchFor(
          "match", 
          0, 
          10000, 
          "id", 
          "asc", 
          "@deleted:false" // Get all matches
        );
        
        console.log(`Total matches from search: ${matchResult.data.total}`);
        
        // Get documents and filter out deleted
        const documents = matchResult.data.documents || [];
        const activeMatches = documents.filter(match => match.deleted !== true);
        console.log(`Active matches (not deleted): ${activeMatches.length}`);
        
        this.totalMatches = activeMatches.length;
        
        // Find invalid matches
        this.invalidMatches = this.findInvalidMatches(activeMatches);
        
        // Log results
        console.log(`Found ${this.invalidMatches.length} invalid matches`);
        
        // Update UI
        this.scanned = true;
        this.MIX_alertBox({ 
          show: true, 
          message: `Scan complete. Found ${this.invalidMatches.length} matches for businesses that no longer exist.`, 
          color: "info", 
          timeout: "5000" 
        });
      } catch (error) {
        console.error("Error scanning matches:", error);
        this.MIX_alertBox({ 
          show: true, 
          message: "Error scanning matches: " + (error.message || "Unknown error"), 
          color: "error", 
          timeout: "4000" 
        });
      } finally {
        this.scanning = false;
      }
    },
    
    // Clean up invalid matches using bulkUpdateDelete method that works with your API
    
async cleanupMatches() {
  this.showConfirmation = false;

  if (this.invalidMatches.length === 0) {
    this.MIX_alertBox({
      show: true,
      message: "No invalid matches found to clean up",
      color: "info",
      timeout: "3000"
    });
    return;
  }

  try {
    this.cleaning = true;
    this.cleanupComplete = false;
    this.showProgressDialog = true;

    // Set total for progress tracking
    this.totalProgress = this.invalidMatches.length;
    this.currentProgress = 0;

    // Tracking counters
    let successCount = 0;
    let errorCount = 0;

    // Process matches in batches
    const batchSize = 20;

    for (let i = 0; i < this.invalidMatches.length; i += batchSize) {
      const batch = this.invalidMatches.slice(i, Math.min(i + batchSize, this.invalidMatches.length));
      this.progressMessage = `Processing matches ${i + 1}-${Math.min(i + batchSize, this.invalidMatches.length)} of ${this.invalidMatches.length}`;

      // Process each match in the batch
      for (let j = 0; j < batch.length; j++) {
        const match = batch[j];

        try {
          const updateData = {
            ...match,
            status: "rejected",
            deleted: true,
            modifiedUserId: this.GET_currentUserProfile?.entityId || "system",
            modifiedDateTime: this.MIX_formatDateTimeNow("toISOString"),
            deletedUserId: this.GET_currentUserProfile?.entityId || "system",
            deletedDateTime: this.MIX_formatDateTimeNow("toISOString")
          };

          await this.REDIS_createWithId("match", updateData, match.id);
          successCount++;
        } catch (createError) {
          console.error(`Error using createWithId for match ${match.id}:`, createError);

          try {
            const url = `${this.apiUrl}match/updatestatus/${match.id}`;
            await this.$axios.post(url, {
              status: "rejected",
              deleted: true,
              modifiedUserId: this.GET_currentUserProfile?.entityId,
              deletedUserId: this.GET_currentUserProfile?.entityId
            }, this.apiOptions);

            successCount++;
          } catch (statusError) {
            errorCount++;
          }
        }

        // Update progress
        this.currentProgress++;
      }
    }

    // Update cleanup stats
    this.lastCleanupCount = successCount;
    this.cleanupComplete = true;

    // Clear invalid matches after cleanup
    this.invalidMatches = []; // Clear out the previous invalid matches

    // Force re-render
    this.$forceUpdate(); // This forces the component to re-render after the data changes

    // Ensure cleanup is complete before proceeding to the scan
    await this.scanMatches(); // Ensure scanMatches is awaited after cleanup

    let message = `Successfully removed ${successCount} invalid matches from the system`;
    if (errorCount > 0) {
      message += ` (${errorCount} errors occurred)`;
    }

    this.MIX_alertBox({
      show: true,
      message: message,
      color: errorCount > 0 ? "warning" : "success",
      timeout: "5000"
    });
  } catch (error) {
    console.error("Error in cleanup process:", error);
    this.MIX_alertBox({
      show: true,
      message: "Error in cleanup process: " + (error.message || "Unknown error"),
      color: "error",
      timeout: "4000"
    });
  } finally {
    this.cleaning = false;
    this.showProgressDialog = false;
  }
},


    
    // Get total match count
    async getMatchCount() {
      try {
        const matchResult = await this.REDIS_searchFor(
          "match", "", "", "", "", "@deleted:{false}"
        );
        this.totalMatches = matchResult.data.total;
      } catch (error) {
        console.error("Error getting match count:", error);
      }
    },
    
    // Initialize component
    async initialize() {
      await this.getMatchCount();
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style scoped>
.v-card {
  border-radius: 8px;
}
</style>