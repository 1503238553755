<template>
  <div>
    <div style="height: 2px !important">
      <v-progress-linear
        :indeterminate="loading"
        :color="loading ? 'primary' : 'grey lighten-2'"
        :value="!loading ? 100 : 0"
      ></v-progress-linear>
    </div>
    <v-row dense class="">
      <!-- CLIENT DETAILS / PROFILE / NOTES -->
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="5"
        class="grey lighten-4"
        style="height: calc(100vh - 60px) !important; overflow-y: hidden"
      >
        <v-row dense>
          <v-col cols="12" class="white" style="min-height: 110px !important">
            <div
              class="text-h5 primary--text d-flex px-5 py-5"
              style="min-height: 100px !important"
            >
              Client:
              <span class="font-weight-bold" v-if="computedClientId === 'new'">New</span>
              <span class="font-weight-bold" v-else
                >{{ org?.contactFirstName }} {{ org?.contactLastName }} {{ org?.name }}</span
              >
            </div>
          </v-col>
          <v-col cols="12" class="grey darken-2 pa-0">
            <v-tabs
              background-color="grey darken-2"
              class="ma-0 pa-0"
              dark
              fixed-tabs
              v-model="orgTab"
            >
              <v-tab href="#profile"> Criteria </v-tab>
              <v-tab href="#details"> Details </v-tab>
              <v-tab href="#notes"> Notes </v-tab>
              <v-tab href="#matches" v-if="$vuetify.breakpoint.width < 880"> Matches </v-tab>

              <!-- ORGANISATION - DETAILS  -->
              <v-tab-item
                v-if="$route.params.id === 'new' || orgFormMode === 'edit'"
                value="details"
                class="grey lighten-3 pa-5"
                style="height: calc(100vh - 225px) !important; overflow-y: auto"
              >
                <v-row dense class="">
                  <v-col cols="6">
                    <DropdownSimple
                      backgroundcolor="white"
                      :items="orgTypes"
                      label="Client Type *"
                      v-model="orgTypeString"
                      :validate="validate.orgTypeString"
                    />
                  </v-col>

                  <v-col cols="6">
                    <DropdownSimple
                      backgroundcolor="white"
                      :items="orgEngaged"
                      label="Client Engaged *"
                      v-model="orgEngagedString"
                      :validate="validate.orgEngagedString"
                    />
                  </v-col>
                  <!-- CONTACT -->
                  <v-col cols="12" class="d-flex align-center">
                    <div class="mt-4 text-h7 font-weight-bold primary--text">CONTACT</div>
                    <v-spacer />
                    <v-btn
                      depressed
                      small
                      class="grey lighten-2"
                      @click="showContact = !showContact"
                    >
                      <span v-if="!showContact">Show</span>
                      <span v-else>Hide</span>
                    </v-btn>
                  </v-col>

                  <v-col cols="12"><v-divider class="mb-2" /></v-col>
                  <v-col cols="6" v-if="showContact">
                    <TextField
                      backgroundcolor="white"
                      label="First Name *"
                      v-model="org.contactFirstName"
                      :validate="validate.contactFirstName"
                    />
                  </v-col>
                  <v-col cols="6" v-if="showContact">
                    <TextField
                      backgroundcolor="white"
                      label="Last Name *"
                      v-model="org.contactLastName"
                      :validate="validate.contactLastName"
                    />
                  </v-col>
                  <v-col cols="12" v-if="showContact">
                    <TextField
                      backgroundcolor="white"
                      label="Job Title"
                      v-model="org.contactJobRole"
                    />
                  </v-col>
                  <v-col cols="12" v-if="showContact">
                    <TextField backgroundcolor="white" label="Company Name" v-model="org.name" />
                  </v-col>
                  <v-col cols="12" v-if="showContact">
                    <TextField
                      backgroundcolor="white"
                      label="Telephone"
                      v-model="org.telephone"
                      :validate="validate.telephone"
                    />
                  </v-col>
                  <v-col cols="6" v-if="showContact">
                    <TextField
                      backgroundcolor="white"
                      label="Email *"
                      v-model.trim="org.email"
                      :validate="validate.email"
                    />
                  </v-col>
                  <v-col cols="6" v-if="showContact">
                    <TextField backgroundcolor="white" label="Website" v-model="org.website" />
                  </v-col>
                  <!--- ADDRESS -->
                  <v-col cols="12" class="d-flex align-center">
                    <div class="mt-4 text-h7 font-weight-bold primary--text">ADDRESS</div>
                    <v-spacer />
                    <v-btn
                      depressed
                      small
                      class="grey lighten-2"
                      @click="showAddress = !showAddress"
                    >
                      <span v-if="!showAddress">Show</span>
                      <span v-else>Hide</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12"><v-divider class="mb-2" /></v-col>
                  <v-col v-if="showAddress" cols="6">
                    <TextField
                      backgroundcolor="white"
                      label="Address Line 1"
                      v-model="org.addressLine1"
                    />
                  </v-col>
                  <v-col v-if="showAddress" cols="6">
                    <TextField
                      backgroundcolor="white"
                      label="Address Line 2"
                      v-model="org.addressLine2"
                    />
                  </v-col>
                  <v-col v-if="showAddress" cols="6">
                    <TextField
                      backgroundcolor="white"
                      label="Address Line 3"
                      v-model="org.addressLine3"
                    />
                  </v-col>
                  <v-col v-if="showAddress" cols="6">
                    <TextField backgroundcolor="white" label="Town" v-model="org.town" />
                  </v-col>
                  <v-col v-if="showAddress" cols="6">
                    <TextField backgroundcolor="white" label="County" v-model="org.county" />
                  </v-col>
                  <v-col v-if="showAddress" cols="6">
                    <TextField
                      backgroundcolor="white"
                      label="Postcode"
                      v-model="org.postcode"
                      :validate="validate.postcode"
                    />
                  </v-col>
                  <v-col v-if="showAddress" cols="12">
                    <DropdownSimple
                      backgroundcolor="white"
                      :items="countries"
                      label="Country"
                      v-model="org.country"
                    />
                  </v-col>
                  <!-- ACCOUNT -->
                  <v-col cols="12" class="d-flex align-center">
                    <div class="mt-4 text-h7 font-weight-bold primary--text">ACCOUNT</div>
                    <v-spacer />
                    <v-btn
                      depressed
                      small
                      class="grey lighten-2"
                      @click="showAccount = !showAccount"
                    >
                      <span v-if="!showAccount">Show</span>
                      <span v-else>Hide</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12"><v-divider class="mb-2" /></v-col>
                  <v-col cols="12" v-if="showAccount"
                    ><DropdownAdvanced
                      backgroundcolor="white"
                      :items="usersName"
                      itemtext="usersName"
                      itemvalue="entityId"
                      label="Account Owner"
                      v-model="org.ownerUserId"
                      :validate="validate.ownerUserId"
                    />
                  </v-col>
                  <v-col cols="12" v-if="showAccount"
                    ><DropdownSimple
                      backgroundcolor="white"
                      :items="orgStatus"
                      label="Status *"
                      v-model="org.status"
                      :validate="validate.status"
                    />
                  </v-col>
                </v-row>
                <v-row dense class="my-4">
                  <v-col cols="12" class="d-flex justify-end align-center">
                    <!-- <div class="flex-grow-1" style="width: 50%"><AppButton block buttonclass="grey" @click.native="closeUser">Cancel</AppButton></div> -->
                    <div class="flex-grow-1" style="width: 50%">
                      <AppButton block buttonclass="grey" @click.native="closeUser('org')">{{
                        "Cancel"
                      }}</AppButton>
                    </div>
                    <div class="flex-grow-1" style="width: 50%">
                      <AppButton block buttonclass="secondary" @click.native="saveOrg">{{
                        $route.params.id === "new" ? "Save" : "Update"
                      }}</AppButton>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>

              <!-- READ ONLY - ORGANISATION - DETAILS -->
              <v-tab-item
                v-if="$route.params.id !== 'new' && orgFormMode !== 'edit'"
                value="details"
                class="grey lighten-3 px-5"
                style="height: calc(100vh - 225px) !important; overflow-y: auto"
              >
                <v-row dense class="">
                  <v-col cols="12" class="d-flex justify-end pt-6" no-gutters>
                    <div class="primary--text" @click="editAction('org')">
                      <v-icon>icons8-edit</v-icon>
                    </div>
                  </v-col>
                  <!-- CLIENT TYPE -->
                  <v-simple-table dense class="grey lighten-3" id="clientData-table">
                    <tbody>
                      <tr>
                        <td class="font-weight-bold pl-0">Client Type:</td>
                        <td>{{ lookupValue(orgTypeString) }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold pl-0">Client Engaged:</td>
                        <td>{{ lookupValue(orgEngagedString) }}</td>
                      </tr>

                      <!-- CONTACT INFO -->
                      <div class="mt-4 text-h7 font-weight-bold primary--text" style="width: 100%">
                        CONTACT
                      </div>
                      <tr>
                        <td class="font-weight-bold px-0">Contact Name:</td>
                        <td>{{ `${org.contactFirstName} ${org.contactLastName}` }}</td>
                      </tr>
                      <tr v-if="org.contactJobRole">
                        <td class="font-weight-bold px-0">Job Title:</td>
                        <td>{{ org.contactJobRole }}</td>
                      </tr>
                      <tr v-if="org.name">
                        <td class="font-weight-bold px-0">Company Name:</td>
                        <td>{{ org.name }}</td>
                      </tr>
                      <tr v-if="org.telephone">
                        <td class="font-weight-bold px-0">Telephone:</td>
                        <td>{{ org.telephone }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold px-0">Email:</td>
                        <td>{{ org.email }}</td>
                      </tr>
                      <tr v-if="org.website">
                        <td class="font-weight-bold px-0">Website:</td>
                        <td>{{ org.website }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <!--- ADDRESS -->
                  <v-col cols="12" class="d-flex align-center">
                    <div class="mt-4 text-h7 font-weight-bold primary--text">ADDRESS</div>
                  </v-col>
                  <v-col cols="12">
                    <span
                      v-if="
                        !org.addressLine1 &&
                        !org.addressLine2 &&
                        !org.addressLine3 &&
                        !org.town &&
                        !org.county &&
                        !org.postcode &&
                        !org.country
                      "
                    >
                      No Address Information Available</span
                    >
                    <span v-if="org.addressLine1" class="text-body-2">{{
                      `${org.addressLine1}`
                    }}</span>
                    <span v-if="org.addressLine2" class="text-body-2">{{
                      `, ${org.addressLine2}`
                    }}</span>
                    <span v-if="org.addressLine3" class="text-body-2">{{
                      `, ${org.addressLine3}`
                    }}</span>
                    <span v-if="org.town">{{ `, ${org.town}` }}</span>
                    <span v-if="org.county" class="text-body-2">{{ `, ${org.county}` }}</span>
                    <span v-if="org.postcode" class="text-body-2">{{ `, ${org.postcode}` }}</span>
                    <span v-if="org.country" class="text-body-2">{{ `, ${org.country}` }}</span>
                  </v-col>

                  <!-- ACCOUNT -->
                  <v-col cols="12" class="d-flex align-center">
                    <div class="mt-4 text-h7 font-weight-bold primary--text">ACCOUNT</div>
                  </v-col>
                  <v-simple-table dense class="grey lighten-3" id="clientData-table">
                    <tbody>
                      <tr>
                        <td class="font-weight-bold px-0">Account Owner:</td>
                        <td>{{ org.ownerUserId }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold px-0">Status:</td>
                        <td>{{ lookupValue(org.status) }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-row>
              </v-tab-item>

              <!-- ORGANISATION - PROFILE -->
              <v-tab-item
                v-if="!orgProfileExists || orgProfileFormMode === 'edit'"
                value="profile"
                class="grey lighten-3 px-5"
                style="height: calc(100vh - 225px) !important; overflow-y: auto"
              >
                <!-- DESCRIPTION -->
                <div class="mt-4 mb-2 text-h7 font-weight-bold primary--text">DESCRIPTION</div>
                <div class="d-flex align-center">
                  <div class="flex-grow-1">
                    <TextArea
                      backgroundcolor="white"
                      label="Description"
                      v-model="orgprofile.description"
                    />
                  </div>
                </div>
                <!-- FINACIALS -->
                <div class="mt-4 mb-2 text-h7 font-weight-bold primary--text">FINANCIALS</div>
                <div class="d-flex align-center my-3">
                  <div class="flex-grow-1">
                    <TextField
                      type="number"
                      backgroundcolor="white"
                      label="Turnover Min"
                      v-model="orgprofile.turnoverMin"
                      :validate="validate.turnoverMin"
                    />
                    <div
                      class="text-overline ml-6"
                      v-if="orgprofile.turnoverMin !== 0 || orgprofile.turnoverMax !== 0"
                    >
                      {{ MIX_formatCurrency(orgprofile.turnoverMin, 0) }}
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <TextField
                      type="number"
                      backgroundcolor="white"
                      label="Turnover Max"
                      v-model="orgprofile.turnoverMax"
                      :validate="validate.turnoverMax"
                    />
                    <div
                      class="text-overline ml-6"
                      v-if="orgprofile.turnoverMin !== 0 || orgprofile.turnoverMax !== 0"
                    >
                      {{ MIX_formatCurrency(orgprofile.turnoverMax, 0) }}
                    </div>
                  </div>
                </div>
                <div class="d-flex align-center my-3">
                  <div class="flex-grow-1">
                    <TextField
                      type="number"
                      backgroundcolor="white"
                      label="Price Min"
                      v-model="orgprofile.priceMin"
                      :validate="validate.priceMin"
                    />
                    <div
                      class="text-overline ml-6"
                      v-if="orgprofile.priceMin !== 0 || orgprofile.priceMax !== 0"
                    >
                      {{ MIX_formatCurrency(orgprofile.priceMin, 0) }}
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <TextField
                      type="number"
                      backgroundcolor="white"
                      label="Price Max"
                      v-model="orgprofile.priceMax"
                      :validate="validate.priceMax"
                    />
                    <div
                      class="text-overline ml-6"
                      v-if="orgprofile.priceMin !== 0 || orgprofile.priceMax !== 0"
                    >
                      {{ MIX_formatCurrency(orgprofile.priceMax, 0) }}
                    </div>
                  </div>
                </div>
                <div class="d-flex align-center my-3">
                  <div class="flex-grow-1">
                    <TextField
                      type="number"
                      backgroundcolor="white"
                      label="EBITDA Min"
                      v-model="orgprofile.ebitdaMin"
                      :validate="validate.ebitdaMin"
                    />
                    <div
                      class="text-overline ml-6"
                      v-if="orgprofile.ebitdaMin !== 0 || orgprofile.ebitdaMax !== 0"
                    >
                      {{ MIX_formatCurrency(orgprofile.ebitdaMin, 0) }}
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <TextField
                      type="number"
                      backgroundcolor="white"
                      label="EBITDA Max"
                      v-model="orgprofile.ebitdaMax"
                      :validate="validate.ebitdaMax"
                    />
                    <div
                      class="text-overline ml-6"
                      v-if="orgprofile.ebitdaMin !== 0 || orgprofile.ebitdaMax !== 0"
                    >
                      {{ MIX_formatCurrency(orgprofile.ebitdaMax, 0) }}
                    </div>
                  </div>
                </div>
                <div class="d-flex align-center">
                  <div class="flex-grow-1">
                    <TextField
                      type="number"
                      backgroundcolor="white"
                      label="Profit Min"
                      v-model="orgprofile.profitMin"
                      :validate="validate.profitMin"
                    />
                    <div
                      class="text-overline ml-6"
                      v-if="orgprofile.profitMin !== 0 || orgprofile.profitMax !== 0"
                    >
                      {{ MIX_formatCurrency(orgprofile.profitMin, 0) }}
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <TextField
                      type="number"
                      backgroundcolor="white"
                      label="Profit Max"
                      v-model="orgprofile.profitMax"
                      :validate="validate.profitMax"
                    />
                    <div
                      class="text-overline ml-6"
                      v-if="orgprofile.profitMin !== 0 || orgprofile.profitMax !== 0"
                    >
                      {{ MIX_formatCurrency(orgprofile.profitMax, 0) }}
                    </div>
                  </div>
                </div>
                <!-- BUSINESS TYPE -->
                <div class="mt-4 mb-2 text-h7 font-weight-bold primary--text">
                  MATCH BUSINESS TYPE<br /><span class="caption"
                    >Choose Sell for Buyers and Buy for Sellers</span
                  >
                </div>
                <div class="flex-grow-1">
                  <DropdownMultiple
                    label="Type"
                    :items="businessTypes"
                    backgroundcolor="white"
                    v-model="orgProfileTypeString"
                  />
                </div>
                <!-- OWNERSHIP -->
                <div class="mt-4 mb-2 text-h7 font-weight-bold primary--text">PROPERTY/TENURE</div>
                <div class="flex-grow-1">
                  <DropdownMultiple
                    label="Property/Tenure"
                    :items="ownership"
                    backgroundcolor="white"
                    v-model="orgProfileOwnershipString"
                  />
                </div>
                <!-- LOCATIONS -->
                <div class="mt-4 mb-2 text-h7 font-weight-bold primary--text">LOCATION</div>
                <div class="flex-grow-1">
                  <DropdownMultiple
                    label="Region"
                    :items="regions"
                    backgroundcolor="white"
                    v-model="orgProfileRegionString"
                  >
                    <template v-slot:selection="{ item }">
                      <div>{{ item }}</div>
                    </template></DropdownMultiple
                  >
                </div>
                <div class="flex-grow-1 mt-3">
                  <DropdownMultiple
                    label="Country"
                    :items="countries"
                    backgroundcolor="white"
                    v-model="orgProfileCountryString"
                  />
                </div>
                <!-- <div class="flex-grow-1 mt-3"><DropdownMultiple label="Loca tions Excluded" :items="locations" backgroundcolor="white" v-model="orgprofile.exLocations" /></div> -->

                <!-- SECTORS -->
                <div class="mt-4 mb-2 text-h7 font-weight-bold primary--text">SECTORS</div>
                <div class="flex-grow-1">
                  <DropdownMultiple
                    label="Sectors Included"
                    :items="sectors"
                    backgroundcolor="white"
                    v-model="orgProfileSectorsString"
                  />
                </div>
                <!-- <div class="flex-grow-1 mt-3"><DropdownMultiple label="Sectors Excluded" :items="sectors" backgroundcolor="white" v-model="orgprofile.exSectors" /></div> -->

                <!-- SUBSECTORS-->
                <div class="mt-4 mb-2 text-h7 font-weight-bold primary--text">SUB SECTORS</div>
                <div class="flex-grow-1">
                  <DropdownMultiple
                    label="Subsectors Included"
                    :items="subsectors"
                    backgroundcolor="white"
                    v-model="orgProfileSubSectorsString"
                  />
                </div>
                <!-- <div class="flex-grow-1 mt-3"><DropdownMultiple label="Subsectors Excluded" :items="subsectors" backgroundcolor="white" v-model="orgprofile.exSubsectors" /></div> -->
                <!-- <div class="mt-4 mb-2 text-h7 font-weight-bold primary--text">Keywords</div>
									<div class="flex-grow-1"><DropdownSimple label="Keywords Included" backgroundcolor="white" v-model="orgprofile.inKeywords" /></div>
									<div class="flex-grow-1 mt-3"><DropdownSimple label="Keywords Excluded" backgroundcolor="white" v-model="orgprofile.exKeywords" /></div> -->
                <!-- KEYWORDS -->
                <div class="mt-4 mb-2 text-h7 font-weight-bold primary--text">KEYWORDS</div>
                <div class="d-flex align-center my-3">
                  <div class="flex-grow-1">
                    <TextField
                      backgroundcolor="white"
                      label="Keywords"
                      v-model="keywordsString"
                      :hint="'Please enter a single word or a list of words separated by commas.'"
                    />
                  </div>
                </div>
                <div><v-switch v-model="orgprofile.enabled" label="Profile Enabled" /></div>
                <v-row dense class="my-4">
                  <v-col cols="12" class="d-flex justify-end align-center">
                    <div class="flex-grow-1" style="width: 50%">
                      <AppButton block buttonclass="grey" @click.native="closeUser('orgProfile')">{{
                        "Cancel"
                      }}</AppButton>
                    </div>
                    <div class="flex-grow-1" style="width: 50%">
                      <AppButton block buttonclass="secondary" @click.native="saveOrgProfile">{{
                        $route.params.id === "new" ? "Save" : "Update"
                      }}</AppButton>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>

              <!-- READ ONLY - ORGANISATION - PROFILE -->
              <v-tab-item
                v-if="orgProfileExists && orgProfileFormMode !== 'edit'"
                value="profile"
                class="grey lighten-3 px-5"
                style="height: calc(100vh - 225px) !important; overflow-y: auto"
              >
                <div class="flex-grow-1 d-flex justify-end pt-4">
                  <div class="primary--text" @click="editAction('orgProfile')">
                    <v-icon>icons8-edit</v-icon>
                  </div>
                </div>
                <!-- DESCRIPTION -->
                <div class="d-flex align-center my-4" v-if="orgprofile.description">
                  <div class="flex-grow-1">
                    <div>{{ orgprofile.description }}</div>
                  </div>
                </div>
                <!-- FINACIALS -->
                <div class="mt-4 mb-2 text-h7 font-weight-bold primary--text">FINANCIALS</div>

                <v-simple-table dense class="grey lighten-3" id="clientData-table">
                  <tbody>
                    <tr v-if="orgprofile.turnoverMin">
                      <td class="font-weight-bold px-0">Turnover Min:</td>
                      <td
                        class="text-overline ml-6"
                        v-if="orgprofile.turnoverMin !== 0 || orgprofile.turnoverMax !== 0"
                      >
                        {{ MIX_formatCurrency(orgprofile.turnoverMin, 0) }}
                      </td>
                    </tr>
                    <tr v-if="orgprofile.turnoverMax">
                      <td class="font-weight-bold px-0">Turnover Max:</td>
                      <td
                        class="text-overline ml-6"
                        v-if="orgprofile.turnoverMin !== 0 || orgprofile.turnoverMax !== 0"
                      >
                        {{ MIX_formatCurrency(orgprofile.turnoverMax, 0) }}
                      </td>
                    </tr>
                    <tr v-if="orgprofile.priceMin">
                      <td class="font-weight-bold px-0">Price Min:</td>
                      <td
                        class="text-overline ml-6"
                        v-if="orgprofile.priceMin !== 0 || orgprofile.priceMax !== 0"
                      >
                        {{ MIX_formatCurrency(orgprofile.priceMin, 0) }}
                      </td>
                    </tr>
                    <tr v-if="orgprofile.priceMax">
                      <td class="font-weight-bold px-0">Price Max:</td>
                      <td
                        class="text-overline ml-6"
                        v-if="orgprofile.priceMin !== 0 || orgprofile.priceMax !== 0"
                      >
                        {{ MIX_formatCurrency(orgprofile.priceMax, 0) }}
                      </td>
                    </tr>
                    <tr v-if="orgprofile.ebitdaMin">
                      <td class="font-weight-bold px-0">EBITDA Min:</td>
                      <td
                        class="text-overline ml-6"
                        v-if="orgprofile.ebitdaMin !== 0 || orgprofile.ebitdaMax !== 0"
                      >
                        {{ MIX_formatCurrency(orgprofile.ebitdaMin, 0) }}
                      </td>
                    </tr>
                    <tr v-if="orgprofile.ebitdaMax">
                      <td class="font-weight-bold px-0">EBITDA Max:</td>
                      <td
                        class="text-overline ml-6"
                        v-if="orgprofile.ebitdaMin !== 0 || orgprofile.ebitdaMax !== 0"
                      >
                        {{ MIX_formatCurrency(orgprofile.ebitdaMax, 0) }}
                      </td>
                    </tr>
                    <tr v-if="orgprofile.profitMin">
                      <td class="font-weight-bold px-0">Profit Min:</td>
                      <td
                        class="text-overline ml-6"
                        v-if="orgprofile.profitMin !== 0 || orgprofile.profitMax !== 0"
                      >
                        {{ MIX_formatCurrency(orgprofile.profitMin, 0) }}
                      </td>
                    </tr>
                    <tr v-if="orgprofile.profitMax">
                      <td class="font-weight-bold px-0">Profit Max:</td>
                      <td
                        class="text-overline ml-6"
                        v-if="orgprofile.profitMin !== 0 || orgprofile.profitMax !== 0"
                      >
                        {{ MIX_formatCurrency(orgprofile.profitMax, 0) }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <!-- OWNERSHIP -->

                <div
                  v-if="orgProfileOwnershipString"
                  class="mt-4 mb-2 text-h7 font-weight-bold primary--text"
                >
                  PROPERTY/TENURE
                </div>
                <div v-if="orgProfileOwnershipString">
                  <v-chip
                    label
                    v-for="(ownership, index) in orgProfileOwnershipString"
                    :key="index"
                    class="mr-1 my-1 secondary lighten-2 primary--text"
                    >{{ lookupValue(ownership) }}</v-chip
                  >
                </div>
                <!-- LOCATIONS -->
                <div
                  v-if="orgProfileRegionString || orgProfileCountryString"
                  class="d-flex flex-column"
                >
                  <span class="mt-4 text-h7 font-weight-bold primary--text">LOCATION</span>
                  <span v-if="orgProfileRegionString"
                    >Region:

                    <v-chip
                      label
                      v-for="(region, index) in orgProfileRegionString"
                      :key="index"
                      class="mr-1 my-1 secondary lighten-2 primary--text"
                      >{{ lookupValue(region) }}</v-chip
                    >
                  </span>
                  <span v-if="orgProfileCountryString"
                    >Country:
                    <v-chip
                      label
                      v-for="(country, index) in orgProfileCountryString"
                      :key="index"
                      class="mr-1 my-1 secondary lighten-2 primary--text"
                      >{{ lookupValue(country) }}</v-chip
                    >
                  </span>
                </div>
                <!-- SECTORS -->
                <div
                  v-if="orgProfileSectorsString"
                  class="mt-4 mb-2 text-h7 font-weight-bold primary--text"
                >
                  SECTORS
                </div>
                <v-chip
                  label
                  v-for="(sector, index) in orgProfileSectorsString"
                  :key="index"
                  class="mr-1 my-1 secondary lighten-2 primary--text"
                  >{{ lookupValue(sector) }}</v-chip
                >
                <!-- SUBSECTORS-->
                <div
                  v-if="orgProfileSubSectorsString"
                  class="mt-4 mb-2 text-h7 font-weight-bold primary--text"
                >
                  SUB SECTORS
                </div>
                <v-chip
                  label
                  v-for="(subsector, index) in orgProfileSubSectorsString"
                  :key="index"
                  class="mr-1 my-1 secondary lighten-2 primary--text"
                  >{{ lookupValue(subsector) }}</v-chip
                >

                <!-- KEYWORDS -->
                <div class="mt-4 mb-2 text-h7 font-weight-bold primary--text">KEYWORDS</div>
                <div class="d-flex align-center my-3">
                  <div class="flex-grow-1">
                    <v-chip
                      label
                      v-for="(keyword, index) in orgprofile.inKeywords"
                      :key="index"
                      class="text-capitalize mr-1 my-1 secondary lighten-2 primary--text"
                      >{{ keyword }}</v-chip
                    >
                  </div>
                </div>
                <div>
                  <v-switch v-model="orgprofile.enabled" :disabled="true" label="Profile Enabled" />
                </div>
              </v-tab-item>

              <!-- 'NOTES + READ ONLY - NOTES -->
              <v-tab-item
                value="notes"
                class="grey lighten-3 px-5 pb-5 pt-2"
                style="height: calc(100vh - 225px) !important; overflow-y: auto"
              >
                <NotesComponent
                  @saveNote="saveNewNote"
                  :orgnotes="notes"
                  :notesmode="noteFormMode"
                  @updateprop="updateNotesProp"
                  :currentuser="GET_currentUserProfile"
                  @saveChanges="editNotes"
                  @deleteNote="deleteNote"
                  id="client"
                />
              </v-tab-item>

              <!-- MATCHES - MOBILE VIEW-->
              <v-tab-item
                v-if="$vuetify.breakpoint.width < 880"
                value="matches"
                class="grey lighten-3 px-2 pb-5 pt-2"
                style="height: calc(100vh - 225px) !important; overflow-y: auto"
              >
                <!-- MATCHES - HEADER -->
                <v-col cols="12" xs="8" class="grey lighten-1">
                  <div class="d-flex flex-column">
                    <!--                                        <div class="d-flex flex-column align-start justify-center">-->
                    <!--                                            <div class="text-h5 font-weight-light primary&#45;&#45;text d-flex align-start"><v-icon class="primary&#45;&#45;text mr-1 text-h3">icons8-match</v-icon>Matches</div>-->
                    <!--                                            <div class="d-flex justify-start">-->
                    <!--                                                <div class="body-2 grey&#45;&#45;text text&#45;&#45;darken-2">-->
                    <!--                                                    Showing <strong>{{ itemsTo === itemsTotal ? itemsTotal : itemsFrom + 1 }}</strong>-->
                    <!--                                                    <span v-if="itemsTo !== itemsTotal">-->
                    <!--                                                        to <strong>{{ itemsTo }}</strong></span-->
                    <!--                                                    >-->
                    <!--                                                    of <strong>{{ itemsTotal }}</strong>-->
                    <!--                                                </div>-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <div class="d-flex justify-center">
                      <v-btn-toggle v-model="matchStatus" multiple>
                        <v-btn value="accepted">
                          <v-icon class="success--text">icons8-filled-circle</v-icon>
                          <span class="ml-2"
                            >Accepted ({{ countMatchesByStatus("accepted") }})</span
                          >
                        </v-btn>
                        <v-btn value="rejected">
                          <v-icon class="error--text">icons8-filled-circle</v-icon>
                          <span class="ml-2"
                            >Rejected ({{ countMatchesByStatus("rejected") }})</span
                          >
                        </v-btn>
                        <v-btn value="pending">
                          <v-icon class="warning--text">icons8-filled-circle</v-icon>
                          <span class="ml-2">Pending ({{ countMatchesByStatus("pending") }})</span>
                        </v-btn>
                      </v-btn-toggle>
                    </div>
                  </div>
                </v-col>
                <!-- MATCHES - TABLE -->
                <v-col
                  cols="12"
                  class="grey lighten-3"
                  style="height: calc(100vh - 160px) !important; overflow-y: scroll"
                >
                  <v-tabs background-color="grey darken-2" dark fixed-tabs v-model="matchTab">
                    <v-tab href="#auto" class="px-1"> Automatic Matches </v-tab>
                    <v-tab href="#manual" class="px-1"> Manual Matches </v-tab>

                    <!-- AUTO MATCHES -->
                    <v-tab-item
                      value="auto"
                      class="grey lighten-3 py-5"
                      style="height: calc(100vh - 225px) !important; overflow-y: scroll"
                    >
                      <table
                        v-if="JSON.stringify(displayedAutoMatches) !== '[]'"
                        class="vertical-table"
                        border="0"
                        width="100%"
                        :options="tableOptions"
                      >
                        <template v-for="item in displayedAutoMatches">
                          <tr :key="item.id" class="white vertical-table-item">
                            <th class="pa-2 rounded-of font-weight-bold">
                              <v-icon :class="MIX_accountStatus(item.status) + '--text'"
                                >icons8-filled-circle</v-icon
                              >
                            </th>
                            <td
                              class="pa-2 rounded-of font-weight-bold pointer-hover"
                              @click="openBusiness(item)"
                            >
                              {{ item.businessTitle }}
                            </td>
                          </tr>
                          <tr :key="'businessType-' + item.entityId" class="white">
                            <th class="pa-2 rounded-of primary--text">Business Type</th>
                            <td class="pa-2 rounded-of">{{ lookupValue(item.businessType) }}</td>
                          </tr>
                          <tr :key="'createdDate-' + item.entityId" class="white">
                            <th class="pa-2 rounded-of primary--text">Date</th>
                            <td class="pa-2 rounded-of">
                              {{
                                MIX_formatDateTimeFromNow(
                                  item.createdDateTime,
                                  "ISOString",
                                  MIX_formatDateTimeFromNow(
                                    item.createdDateTime,
                                    "ISOString",
                                    "Days"
                                  ) === "Today"
                                    ? "Days"
                                    : "Default"
                                )
                              }}
                            </td>
                          </tr>
                          <tr :key="'matchPercent-' + item.entityId" class="white">
                            <th class="pa-2 rounded-of primary--text">Match</th>
                            <td class="pa-2 rounded-of font-weight-bold">
                              {{ item.matchPercent }} %
                            </td>
                          </tr>
                          <tr :key="'enquiryStatus-' + item.entityId" class="white">
                            <th class="pa-2 rounded-of primary--text">Enquiry Status</th>
                            <td class="pa-2 rounded-of" v-if="item.enquiryStatus">
                              {{ item.enquiryStatus }}
                            </td>
                            <td class="pa-2 rounded-of" v-else>-</td>
                          </tr>
                          <tr :key="'spacer1-' + item.entityId"></tr>
                          <tr :key="'spacer2-' + item.entityId">
                            <td colspan="2" class="spacer-cell"></td>
                          </tr>
                        </template>
                      </table>

                      <div
                        v-if="this.orgprofile.orgId === ''"
                        class="d-flex align-start justify-center pt-10"
                      >
                        <div
                          v-if="this.orgprofile.orgId !== ''"
                          class="text-h6 font-weight-bold primary--text"
                        >
                          No Matches Found
                        </div>
                        <div
                          v-if="this.orgprofile.orgId === ''"
                          class="text-h6 font-weight-bold primary--text"
                        >
                          No Match Profile Found
                        </div>
                      </div>
                    </v-tab-item>
                    <!-- MANUAL MATCHES -->
                    <v-tab-item
                      value="manual"
                      class="grey lighten-3 px-5 pb-5 pt-2"
                      style="height: calc(100vh - 225px) !important; overflow-y: scroll"
                    >
                      <table
                        v-if="JSON.stringify(displayedManualMatches) !== '[]'"
                        class="vertical-table"
                        border="0"
                        width="100%"
                        :options="tableOptions"
                      >
                        <template v-for="item in displayedManualMatches">
                          <tr :key="item.entityId" class="white vertical-table-item">
                            <th class="pa-2 rounded-of font-weight-bold" width="60">
                              <v-icon :class="MIX_accountStatus(item.status) + '--text'"
                                >icons8-filled-circle</v-icon
                              >
                            </th>
                            <td
                              class="pa-2 rounded-of font-weight-bold"
                              @click="openBusiness(item)"
                            >
                              {{ item.businessTitle }}
                            </td>
                          </tr>
                          <tr :key="'businessType-' + item.entityId" class="white">
                            <th class="pa-2 rounded-of primary--text">Business Type</th>
                            <td class="pa-2 rounded-of">{{ lookupValue(item.businessType) }}</td>
                          </tr>
                          <tr :key="'createdDate-' + item.entityId" class="white">
                            <th class="pa-2 rounded-of">Date</th>
                            <td class="pa-2 rounded-of">
                              {{
                                MIX_formatDateTimeFromNow(
                                  item.createdDateTime,
                                  "ISOString",
                                  MIX_formatDateTimeFromNow(
                                    item.createdDateTime,
                                    "ISOString",
                                    "Days"
                                  ) === "Today"
                                    ? "Days"
                                    : "Default"
                                )
                              }}
                            </td>
                          </tr>
                          <tr :key="'matchPercent-' + item.entityId" class="white">
                            <th class="pa-2 rounded-of primary--text">Match</th>
                            <td class="pa-2 rounded-of font-weight-bold">
                              {{ item.matchPercent }} %
                            </td>
                          </tr>
                          <tr :key="'enquiryStatus-' + item.entityId" class="white">
                            <th class="pa-2 rounded-of primary--text">Enquiry Status</th>
                            <td class="pa-2 rounded-of" v-if="item.enquiryStatus">
                              {{ item.enquiryStatus }}
                            </td>
                            <td class="pa-2 rounded-of" v-else>-</td>
                          </tr>
                          <tr :key="'spacer3-' + item.entityId"></tr>
                          <tr :key="'spacer4-' + item.entityId">
                            <td colspan="2" class="spacer-cell"></td>
                          </tr>
                        </template>
                      </table>

                      <div
                        v-if="JSON.stringify(displayedManualMatches) == '[]'"
                        class="d-flex align-start justify-center pt-10 text-h6 font-weight-bold primary--text"
                      >
                        No Manual Matches Found
                      </div>

                      <div
                        v-if="this.orgprofile.orgId === ''"
                        class="d-flex align-start justify-center pt-10"
                      >
                        <div
                          v-if="this.orgprofile.orgId !== ''"
                          class="text-h6 font-weight-bold primary--text"
                        >
                          No Manual Matches Found
                        </div>
                      </div>
                    </v-tab-item>
                  </v-tabs>
                </v-col>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-divider vertical class="grey darken-3" style="height: 100vh" /> -->
      <!-- MATCHES - DESKTOP VIEW -->
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="7"
        v-if="$vuetify.breakpoint.width >= 880"
        style="height: calc(100vh - 60px) !important; overflow-y: hidden"
      >
        <v-row dense>
          <!-- MATCHES - HEADER -->
          <v-col
            cols="12"
            class="grey lighten-1 d-flex align-center"
            style="min-height: 110px !important"
          >
            <!--                        <div class="d-flex flex-column align-end justify-center">-->
            <!--                            <div class="px-2 pt-2 text-h5 font-weight-light primary&#45;&#45;text d-flex align-center"><v-icon class="primary&#45;&#45;text ml-5 mr-1 text-h3">icons8-match</v-icon>Matches</div>-->
            <!--                            <div class="d-flex justify-end px-2" style="width: 100% !important">-->
            <!--                                <div class="body-2 grey&#45;&#45;text text&#45;&#45;darken-2">-->
            <!--                                    Showing <strong>{{ itemsTo === itemsTotal ? itemsTotal : itemsFrom + 1 }}</strong>-->
            <!--                                    <span v-if="itemsTo !== itemsTotal">-->
            <!--                                        to <strong>{{ itemsTo }}</strong></span-->
            <!--                                    >-->
            <!--                                    of <strong>{{ itemsTotal }}</strong>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <v-spacer />

            <div class="full-width d-flex align-center justify-end mr-3">
              <div>
                <v-btn
                  v-if="matchTab === 'auto' && JSON.stringify(displayedAutoMatches) !== '[]'"
                  @click="openSendMatchesDialog('auto')"
                  class="elevation-0"
                  style="height: 48px"
                  >Send Auto Matches</v-btn
                >
                <v-btn
                  v-if="matchTab === 'manual' && JSON.stringify(displayedManualMatches) !== '[]'"
                  @click="openSendMatchesDialog('manual')"
                  class="elevation-0"
                  style="height: 48px"
                  >Send Manual Matches</v-btn
                >
              </div>
              <v-btn-toggle class="mt-0" v-model="matchStatus" multiple>
                <v-btn value="accepted">
                  <v-icon class="success--text">icons8-filled-circle</v-icon>
                  <span class="ml-2">Accepted ({{ countMatchesByStatus("accepted") }})</span>
                </v-btn>
                <v-btn value="rejected">
                  <v-icon class="error--text">icons8-filled-circle</v-icon>
                  <span class="ml-2">Rejected ({{ countMatchesByStatus("rejected") }})</span>
                </v-btn>
                <v-btn value="pending">
                  <v-icon class="warning--text">icons8-filled-circle</v-icon>
                  <span class="ml-2">Pending ({{ countMatchesByStatus("pending") }})</span>
                </v-btn>
              </v-btn-toggle>
            </div>
          </v-col>
          <!-- MATCHES - TABLE -->
          <v-col
            cols="12"
            class="grey darken-2 pa-0"
            style="height: calc(100vh - 160px) !important; overflow-y: none"
          >
            <v-tabs
              background-color="grey darken-2"
              class="ma-0 pa-0"
              dark
              fixed-tabs
              v-model="matchTab"
            >
              <v-tab href="#auto"> Automatic Matches </v-tab>
              <v-tab href="#manual"> Manual Matches </v-tab>

              <!-- AUTO MATCHES -->
              <v-tab-item
                value="auto"
                class="grey lighten-3 pa-5"
                style="height: calc(100vh - 225px) !important; overflow-y: scroll"
              >
                <div 
                  v-if="!loading && displayedAutoMatches.length !== 0" 
                  class="d-flex align-center gap-10"
                >
                  <AppButton @click.native="previous()" :disabled="this.tableOptions.page === 1"
                    ><v-icon class="primary--text">icons8-previous</v-icon></AppButton
                  >
                  <div class="body-2 grey--text text--darken-2">
                    Showing <strong>{{ itemsFrom + 1 }}</strong>
                    <span v-if="itemsTo !== itemsTotal">
                      to <strong>{{ itemsTo }}</strong></span
                    >
                    of <strong>{{ itemsTotal }}</strong>
                  </div>
                  <AppButton
                    @click.native="next()"
                    :disabled="tableOptions.page * tableOptions.itemsPerPage >= itemsTotal"
                    ><v-icon class="primary--text">icons8-next</v-icon></AppButton
                  >
                </div>
                <div v-if="loading" class="d-flex justify-center pt-10">
                  <v-progress-circular
                    :indeterminate="loading"
                    class="text-center"
                    size="108"
                    :color="loading ? 'primary' : 'grey lighten-2'"
                    :value="!loading ? 100 : 0"
                  >
                    {{ "Loading Matches" }}
                  </v-progress-circular>
                </div>
                <table
                  v-if="!loading && JSON.stringify(displayedAutoMatches) !== '[]'"
                  class="my-4"
                  border="0"
                  width="100%"
                  :options="tableOptions"
                >
                  <tr class="primary--text">
                    <th class="py-2 px-2" align="center">Status</th>
                    <th class="py-2 pl-4" align="left">Business</th>
                    <th class="py-2 pl-4" align="left">Type</th>
                    <th class="py-2 pl-4" align="left">Date</th>
                    <th class="py-2" align="center">Match</th>
                  </tr>
                  <template v-for="item in displayedAutoMatches">
                    <tr :key="item.entityId" class="white">
                      <td class="py-2 pl-4 rounded-of font-weight-bold" width="60">
                        <v-icon :class="MIX_accountStatus(item.status) + '--text'"
                          >icons8-filled-circle</v-icon
                        >
                      </td>
                      <td class="py-2 pl-4 rounded-of font-weight-bold">
                        {{ item.businessTitle }}
                      </td>
                      <td class="py-2 pl-4 rounded-of">{{ lookupValue(item.businessType) }}</td>
                      <td
                        class="success--text py-2 pl-4 rounded-of"
                        width="100"
                        v-if="
                          MIX_formatDateTimeFromNow(item.createdDateTime, 'ISOString', 'Days') ===
                          'Today'
                        "
                      >
                        {{ MIX_formatDateTimeFromNow(item.createdDateTime, "ISOString", "Days") }}
                      </td>
                      <td
                        class="warning--text py-2 pl-4 rounded-of"
                        width="100"
                        v-else-if="
                          MIX_formatDateTimeFromNow(item.createdDateTime, 'ISOString', 'Days') ===
                          'Yesterday'
                        "
                      >
                        Yesterday
                      </td>
                      <td v-else class="warning--text py-2 pl-4 rounded-of" width="100">
                        {{ MIX_formatDateTimeFromNow(item.createdDateTime, "ISOString", "Days") }}
                      </td>
                      <td class="py-2 rounded-of font-weight-bold" align="center" width="60">
                        {{ item.matchPercent }} %
                      </td>
                      <td class="grey lighten-3" width="50" align="right">
                        <v-btn @click="openBusiness(item)" icon
                          ><v-icon large>icons8-right-button</v-icon></v-btn
                        >
                      </td>
                    </tr>

                    <tr :key="'enquiry-' + item.entityId" class="white">
                      <th class="px-2">Enquiry Status</th>

                      <td class="pl-4 rounded-of" v-if="item.enquiryStatus">
                        {{ item.enquiryStatus }}
                      </td>
                      <td class="py-2 pl-4 rounded-of" v-else>-</td>
                      <td class="rounded-of"></td>
                      <td class="rounded-of"></td>
                      <td class="rounded-of"></td>
                    </tr>
                    <tr :key="'spacer1-' + item.entityId"></tr>
                    <tr :key="'spacer2-' + item.entityId"></tr>
                  </template>
                </table>
                <!-- <div v-if="displayedAutoMatches.length < filteredMatchesByStatus.length" class="text-center mb-4">
                            <AppButton @click.native="loadMore" class="my-2">Load More</AppButton>
                        </div> -->
                <!-- v-if="matchTab === 'auto' && JSON.stringify(displayedAutoMatches) !== '[]' -->
                <div
                  v-if="!loading && displayedAutoMatches.length === 0"
                  class="d-flex align-start justify-center pt-10 text-h6 font-weight-bold primary--text"
                >
                  No Automatic Matches Found
                </div>
                <div v-if="orgprofile.orgId === ''" class="d-flex align-start justify-center pt-10">
                  <div
                    v-if="orgprofile.orgId === ''"
                    class="text-h6 font-weight-bold primary--text"
                  >
                    No Match Profile Found
                  </div>
                </div>
              </v-tab-item>
              <!-- MANUAL MATCHES -->
              <v-tab-item
                value="manual"
                class="grey lighten-3 px-5 pb-5 pt-2"
                style="height: calc(100vh - 225px) !important; overflow-y: scroll"
              >
                <div 
                  v-if="!loading && displayedManualMatches.length !== 0" 
                  class="d-flex align-center gap-10"
                >
                  <AppButton @click.native="previous()" :disabled="this.tableOptions.page === 1"
                    ><v-icon class="primary--text">icons8-previous</v-icon></AppButton
                  >
                  <div class="body-2 grey--text text--darken-2">
                    Showing <strong>{{ itemsFrom + 1 }}</strong>
                    <span v-if="itemsTo !== itemsTotal">
                      to <strong>{{ itemsTo }}</strong></span
                    >
                    of <strong>{{ itemsTotal }}</strong>
                  </div>
                  <AppButton
                    @click.native="next()"
                    :disabled="tableOptions.page * tableOptions.itemsPerPage >= itemsTotal"
                    ><v-icon class="primary--text">icons8-next</v-icon></AppButton
                  >
                </div>
                <div v-if="loading" class="d-flex justify-center pt-10">
                  <v-progress-circular
                    :indeterminate="loading"
                    class="text-center"
                    size="108"
                    :color="loading ? 'primary' : 'grey lighten-2'"
                    :value="!loading ? 100 : 0"
                  >
                    {{ "Loading Matches" }}
                  </v-progress-circular>
                </div>
                <table
                  v-if="!loading && displayedManualMatches.length > 0"
                  class="my-4"
                  border="0"
                  width="100%"
                  :options="tableOptions"
                >
                  <tr class="primary--text">
                    <th class="py-2 px-2" align="center">Status</th>
                    <th class="py-2 pl-4" align="left">Business</th>
                    <th class="py-2 pl-4" align="left">Type</th>
                    <th class="py-2 pl-4" align="left">Date</th>
                    <th class="py-2" align="center">Match</th>
                  </tr>
                  <template v-for="item in displayedManualMatches">
                    <tr :key="item.entityId" class="white">
                      <td class="py-2 pl-4 rounded-of font-weight-bold" width="60">
                        <v-icon :class="MIX_accountStatus(item.status) + '--text'"
                          >icons8-filled-circle</v-icon
                        >
                      </td>
                      <td class="py-2 pl-4 rounded-of font-weight-bold">
                        {{ item.businessTitle }}
                      </td>
                      <td class="py-2 pl-4 rounded-of">{{ lookupValue(item.businessType) }}</td>
                      <td
                        class="success--text py-2 pl-4 rounded-of"
                        width="100"
                        v-if="
                          MIX_formatDateTimeFromNow(item.createdDateTime, 'ISOString', 'Days') ===
                          'Today'
                        "
                      >
                        {{ MIX_formatDateTimeFromNow(item.createdDateTime, "ISOString", "Days") }}
                      </td>
                      <td
                        class="warning--text py-2 pl-4 rounded-of"
                        width="100"
                        v-else-if="
                          MIX_formatDateTimeFromNow(item.createdDateTime, 'ISOString', 'Days') ===
                          'Yesterday'
                        "
                      >
                        Yesterday
                      </td>
                      <td v-else class="warning--text py-2 pl-4 rounded-of" width="100">
                        {{ MIX_formatDateTimeFromNow(item.createdDateTime, "ISOString", "Days") }}
                      </td>
                      <td class="py-2 rounded-of font-weight-bold" align="center" width="60">
                        {{ item.matchPercent }} %
                      </td>
                      <td class="grey lighten-3" width="50" align="right">
                        <v-btn @click="openBusiness(item)" icon
                          ><v-icon large>icons8-right-button</v-icon></v-btn
                        >
                      </td>
                    </tr>
                    <tr :key="'enquiry2-' + item.entityId" class="white">
                      <th class="px-2">Enquiry Status</th>

                      <td class="pl-4 rounded-of" v-if="item.enquiryStatus">
                        {{ item.enquiryStatus }}
                      </td>
                      <td class="py-2 pl-4 rounded-of" v-else>-</td>
                      <td class="rounded-of"></td>
                      <td class="rounded-of"></td>
                      <td class="rounded-of"></td>
                    </tr>
                    <tr :key="'spacer3-' + item.entityId"></tr>
                    <tr :key="'spacer4-' + item.entityId"></tr>
                  </template>
                </table>
                <div
                  v-if="!loading && displayedManualMatches.length === 0"
                  class="d-flex align-start justify-center pt-10 text-h6 font-weight-bold primary--text"
                >
                  No Manual Matches Found
                </div>

                <div
                  v-if="this.orgprofile.orgId === ''"
                  class="d-flex align-start justify-center pt-10"
                >
                  <div
                    v-if="this.orgprofile.orgId !== ''"
                    class="text-h6 font-weight-bold primary--text"
                  >
                    No Match Profile Found
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>

      <!-- BUSINESS - DRAWER - DESKTOP VIEW -->
      <v-navigation-drawer
        :width="$vuetify.breakpoint.width >= 600 ? '550px' : '400px'"
        class="grey lighten-3"
        v-model="businessDrawer"
        absolute
        clipped
        right
      >
        <!-- BUSINESS HEADER -->
        <v-row dense class="mt-5">
          <v-col cols="12">
            <div class="d-flex flex-column align-space-between">
              <div class="d-flex align-start mb-2" style="height: 70px">
                <div class="px-5 text-h5 primary--text">
                  Match: <span class="font-weight-bold">{{ business?.title }}</span>
                  <!-- <v-icon title="Star" class="ml-1 grey--text">icons8-rating</v-icon> -->
                </div>
                <v-spacer />
                <v-btn class="pr-5" icon depressed @click="businessDrawer = false"
                  ><v-icon>icons8-close</v-icon></v-btn
                >
              </div>
              <div>
                <v-chip class="ml-5" :class="MIX_accountStatus(match?.status)"
                  >Match {{ lookupValue(match.status) }}</v-chip
                >
              </div>
            </div>
          </v-col>
          <!-- BUSINESS TABS -->
          <v-col cols="12" class="grey lighten-3">
            <v-tabs v-model="businessTabs" fixed-tabs background-color="primary" dark>
              <v-tab href="#description"> Description </v-tab>
              <v-tab href="#financials"> Financials </v-tab>
              <v-tab href="#match"> Match </v-tab>
              <v-tab href="#status"> Status </v-tab>
              <v-tab href="#notes"> Notes </v-tab>

              <!-- DESCRIPTION -->
              <v-tab-item
                value="description"
                class="white pa-5 pt-2"
                style="height: calc(100vh - 230px); overflow: scroll"
              >
                <div
                  class="mt-4 text-body-1"
                  v-if="JSON.stringify(business.location) !== '[]' && business?.location"
                >
                  <div class="text-h6 primary--text">Location</div>
                  <v-chip
                    label
                    v-for="(location, index) in business.location"
                    :key="index"
                    class="mr-1 my-1 secondary lighten-2 primary--text"
                  >
                    <span v-if="lookupValue(location) !== ''">{{ lookupValue(location) }}</span>
                    <span v-else>{{ location }}</span>
                  </v-chip>
                </div>
                <div
                  class="mt-4 text-body-1"
                  v-if="JSON.stringify(business.sectors) !== '[]' && business?.sectors"
                >
                  <div class="text-h6 primary--text">Sectors</div>
                  <v-chip
                    label
                    v-for="(sector, index) in business.sectors"
                    :key="index"
                    class="mr-1 my-1 secondary lighten-2 primary--text"
                    >{{ lookupValue(sector) }}</v-chip
                  >
                </div>
                <div
                  class="mt-4 text-body-1"
                  v-if="JSON.stringify(business.subsectors) !== '[]' && business?.subsectors"
                >
                  <div class="text-h6 primary--text">Subsectors</div>
                  <v-chip
                    label
                    v-for="(subsector, index) in business.subsectors"
                    :key="index"
                    class="mr-1 my-1 secondary lighten-2 primary--text"
                    >{{ lookupValue(subsector) }}</v-chip
                  >
                </div>
                <div class="mt-4 text-h6 primary--text">Description</div>
                <div class="mt-2 text-body-1" v-html="business?.description"></div>
                <div
                  class="mt-4 text-body-1"
                  :style="
                    $vuetify.breakpoint.width >= 600 && $vuetify.breakpoint.width < 880
                      ? 'padding-bottom: 80px;'
                      : ''
                  "
                  v-if="JSON.stringify(business.keywords) !== '[]' && business?.keywords"
                >
                  <div class="text-h6 primary--text">Keywords</div>
                  <v-chip
                    @click:close="addIgnoreKeyword(keyword)"
                    close
                    close-icon="mdi-delete"
                    label
                    v-for="(keyword, index) in filterIgnoreList(business.keywords)"
                    :key="index"
                    class="mr-1 my-1 secondary lighten-2 primary--text"
                    >{{ keyword }}</v-chip
                  >
                </div>
              </v-tab-item>

              <!-- FINANCIALS -->
              <v-tab-item value="financials" class="grey lighten-3">
                <div class="mt-2 px-5">
                  <SimpleTable :tabledata="computedBusiness" class="my-3 white" />
                </div>
              </v-tab-item>

              <!-- 'NOTES -->
              <v-tab-item value="notes" class="grey lighten-3">
                <NotesComponent
                  @saveNote="saveNewNote"
                  :orgnotes="matchNotes"
                  :notesmode="matchNoteFormMode"
                  @updateprop="updateNotesProp"
                  :currentuser="GET_currentUserProfile"
                  @saveChanges="editNotes"
                  @deleteNote="deleteNote"
                  id="orgMatch"
                  class="ma-3"
                />
              </v-tab-item>

              <!-- MATCH -->
              <v-tab-item
                value="match"
                class="grey lighten-3 pa-5 pt-2"
                style="height: calc(100vh - 230px); overflow: scroll"
              >
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-avatar color="primary" size="100">
                      <div class="white--text text-h5">{{ match.matchPercent }}%</div>
                    </v-avatar>
                    <div class="mt-2 primary--text body-1 font-weight-bold">MATCH</div>
                  </v-col>
                  <v-col cols="12">
                    <SimpleTable :tabledata="matchReport" class="my-3 white" />
                  </v-col>
                  <v-col cols="12"
                    ><DropdownSimple
                      backgroundcolor="white"
                      :items="matchReasons"
                      label="Reason"
                      v-model="match.reason"
                      v-if="match.status === 'rejected'"
                      :validate="validate.reason"
                  /></v-col>
                  <v-col cols="12"
                    ><TextArea
                      backgroundcolor="white"
                      label="Comments"
                      v-model="match.reasonNotes"
                      v-if="match.status !== 'pending'"
                  /></v-col>
                  <v-col cols="12">
                    <div class="d-flex align-center">
                      <div class="flex-grow-1">
                        <AppButton
                          :disabled="match.status !== 'pending' && match.status !== 'accepted'"
                          block
                          buttonclass="error"
                          @click.native="updateMatch('rejected')"
                          >Reject Match</AppButton
                        >
                      </div>
                      <div class="flex-grow-1">
                        <AppButton
                          :disabled="match.status !== 'pending' && match.status !== 'rejected'"
                          block
                          buttonclass="success"
                          @click.native="updateMatch('accepted')"
                          >Accept Match</AppButton
                        >
                      </div>
                    </div>
                    <div class="flex-grow-1 mt-2" v-if="match.status !== 'pending'">
                      <AppButton block buttonclass="success" @click.native="confirmMatch()"
                        >Confirm Choice</AppButton
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
              <!-- STATUS -->
              <v-tab-item
                value="status"
                class="grey lighten-3 pa-5 pt-2"
                style="height: calc(100vh - 230px); overflow: scroll"
              >
                <v-row>
                  <v-col cols="12" class="text-center">
                    <div class="mt-2 primary--text body-1 font-weight-bold">STATUS OF ENQUIRY</div>
                  </v-col>
                  <v-col cols="12">
                    <DropdownSimple
                      backgroundcolor="white"
                      :disabled="match.status === 'pending'"
                      :items="
                        match.status === 'rejected' ? enquiryStatusRejected : enquiryStatusApproved
                      "
                      label="Reason"
                      v-model="match.enquiryStatus"
                      :validate="validate.enquiryStatus"
                    />
                  </v-col>
                  <v-col cols="12">
                    <div class="flex-grow-1 mt-2" v-if="match.status !== 'pending'">
                      <AppButton block buttonclass="success" @click.native="saveStatus()"
                        >Confirm Status</AppButton
                      >
                    </div>
                    <div class="red--text text-center" v-else>
                      To choose a Status, the Match must be either accepted or rejected.
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-navigation-drawer>

      <!-- CONFIRMATION BOX -->
      <ConfirmBox
        headerclass="primary"
        footerclass="grey lighten-2"
        :value="confirmBox"
        v-on:close="confirmBox = false"
      >
        <template v-slot:header>
          <div class="full-width d-flex align-center">
            <div>Confirm</div>
            <v-spacer />
            <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
          </div>
        </template>
        <p>Please confirm you want to delete this Client</p>
        <strong>{{ org.name }}</strong
        ><br />
        <!-- permenantDelete : {{ permenantDelete }}<br /> -->
        <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
        <template v-slot:footer>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
              <AppButton buttonclass="warning" @click.native="confirmDeleteOrg">Confirm</AppButton>
            </v-col>
          </v-row>
        </template>
      </ConfirmBox>

      <!-- 'NOTES CONFIRMATION BOX -->
      <ConfirmBox
        headerclass="primary"
        footerclass="grey lighten-2"
        :value="confirmBoxNotes"
        v-on:close="confirmBoxNotes = false"
      >
        <template v-slot:header>
          <div class="full-width d-flex align-center">
            <div>Confirm</div>
            <v-spacer />
            <v-btn icon depressed @click="confirmBoxNotes = false"
              ><v-icon>icons8-close</v-icon></v-btn
            >
          </div>
        </template>
        <p>Please confirm you want to delete this Note</p>
        <template v-slot:footer>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <AppButton buttonclass="grey" @click.native="confirmBoxNotes = false"
                >Cancel</AppButton
              >
              <AppButton buttonclass="warning" @click.native="confirmDeleteNote">Confirm</AppButton>
            </v-col>
          </v-row>
        </template>
      </ConfirmBox>

      <!-- SEND MATCHES DIALOG  -->

      <v-dialog style="z-index: 3000 !important" v-model="sendMatchesDialog" persistent width="712">
        <v-card class="grey lighten-4">
          <v-card-title class="primary white--text">
            {{
              currentMatchType === "auto" ? "Selected Automatic Matches" : "Selected Manual Matches"
            }}
          </v-card-title>
          <v-card-text v-if="!dialogLoader" class="pa-0 mt-4 mb-0">
            <div class="mr-8" style="height: 350px !important; overflow: auto !important">
              <div v-for="(item, i) in fullMatchesArray" :key="i" class="d-flex mx-4 px-4 mr-0">
                <v-checkbox v-model="item.selected"></v-checkbox>
                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div class="d-flex align-center primary--text font-weight-bold">
                        <div
                          class="d-flex flex-column align-start justify-start"
                          style="gap: 0px !important"
                        >
                          <span class="pb-0 mb-0">{{ item.title }}</span>
                          <span
                            v-if="item.sent && item.sent == true"
                            class="font-weight-regular pt-2 mt-0"
                            >Email Sent on
                            <span class="font-weight-bold">{{
                              MIX_formatDateTime(
                                item.sentDateTime,
                                "ISOString",
                                "DD MMM YYYY, HH:mm"
                              )
                            }}</span></span
                          >
                          <span
                            v-if="item.read && item.read == true"
                            class="font-weight-regular pt-1 mt-0"
                            >Email Last Opened on
                            <span class="font-weight-bold">{{
                              MIX_formatDateTime(
                                item.readDateTime,
                                "ISOString",
                                "DD MMM YYYY, HH:mm"
                              )
                            }}</span></span
                          >
                        </div>

                        <v-spacer />
                        {{ item.matchPercent }}%
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="d-flex flex-column justify-space-between">
                        <div>
                          <v-icon class="mr-2">icons8-location-2</v-icon>
                          <v-chip
                            label
                            v-for="(location, index) in item?.location"
                            :key="index"
                            class="mr-1 my-1 secondary lighten-2 primary--text"
                          >
                            <span v-if="lookupValue(location) !== ''">{{
                              lookupValue(location)
                            }}</span>
                            <span v-else>{{ location }}</span>
                          </v-chip>
                        </div>
                        <div class="d-flex justify-space-between">
                          <div>
                            <v-icon class="mr-2">icons8-total-sales</v-icon>
                            £{{ item?.turnover }}
                          </div>
                          <div>
                            <v-icon class="mr-2">icons8-company</v-icon>
                            <span class="text-capitalize">{{ item?.type }}</span>
                          </div>
                          <div>
                            <v-icon class="mr-2">icons8-globe</v-icon>
                            <a :href="item.sourceURL">{{ item?.sourceName }}</a>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-center">
                        <div style="flex-grow: 1">
                          {{ item.summary }}
                        </div>
                        <v-btn
                          icon
                          small
                          title="Copy summary to clipboard"
                          @click="copyToClipboard(item.summary)"
                          class="ml-2"
                        >
                          <v-icon small>mdi-content-copy</v-icon>
                        </v-btn>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
            <div class="px-8 pt-4 mb-0">
              <div class="text-body-1 grey--text text-lighten-2 font-weight-bold">
                Add Custom Message to Client
              </div>
              <v-textarea
                background-color="white"
                auto-grow
                outlined
                v-model="customMessage"
              ></v-textarea>
            </div>
          </v-card-text>
          <v-card-text
            v-else-if="dialogLoader"
            class="pa-0 py-10 d-flex align-center justify-center mt-4 mb-0"
          >
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-card-text>
          <v-card-actions class="text-center mt-0 pt-0">
            <!--Dialog Actions-->
            <v-row class="ma-0 pa-0" justify="center">
              <v-col cols="6" class="pt-0">
                <AppButton
                  @click.native="sendMatchesDialog = !sendMatchesDialog"
                  buttonclass="primary white--text"
                  block
                  >Cancel</AppButton
                >
              </v-col>
              <v-col cols="6" class="pt-0">
                <AppButton block @click.native="sendMatches()" buttonclass="green white--text"
                  >Send Email</AppButton
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ClientView",
  // data
  data: () => ({
    loading: false,
    // GENERAL
    users: [],

    // FORM RELATED
    orgProfileExists: false,
    orgFormMode: "",
    orgProfileFormMode: "",
    matchNoteFormMode: "",
    noteFormMode: "",
    keywordsString: "",
    orgTypeString: "",
    orgEngagedString: "",
    orgProfileRegionString: "",
    orgProfileTypeString: "",
    orgProfileOwnershipString: "",
    orgProfileCountryString: "",
    orgProfileSectorsString: "",
    orgProfileSubSectorsString: "",

    // ACCOUNT RELATED
    usersName: [],

    // LOOKUPS
    lookups: [],
    sectors: [],
    subsectors: [],
    locations: [],
    ownership: [],
    regions: [],
    keywords: [],
    countries: [],
    ignorekeywordlist: [],

    orgTypes: [],
    orgStatus: [],
    orgGroups: [],
    orgLevels: [],
    orgEngaged: [],
    confirmBox: false,

    // ORGANISATION RELATED
    orgprofile: {},
    orgTab: "details",
    org: {},
    showAddress: false,
    showAccount: true,
    showContact: true,

    // Validation Data Object
    validate: {},

    // BUSINESS RELATED
    businessDrawer: false,
    businessTabs: "details",
    business: {},
    businessTypes: [],

    // STATUS RELATED
    enquiryStatusApproved: [
      "Match Pending Approval (AR:Deals)",
      "Match Pending Approval (Manual)",
      "Match Verified",
      "Match Shared with Client",
      "Client Accepted – Info Required",
      "Client Accepted – Information Shared",
      "Client Accepted – Meeting Scheduled",
      "Client Accepted – Offer Made",
      "Client Accepted – Deal Completed",
    ],
    enquiryStatusRejected: [
      "Match Rejected",
      "Client Rejected",
      "Client Accepted – Not Interested",
      "Client Accepted – Deal Aborted",
    ],

    // MATCH RELATED
    matchStatus: [],
    match: {},
    matchReasons: [
      "Sector Mis-Match",
      "Financial Mis-Match",
      "Geography Mis-Match",
      "Seen Opportunity Elsewhere Previously",
      "Previously Discarded by GS Verde",
      "Client Has Niche Requirements",
    ],
    matchTab: "matches",

    showResult: false,

    // NOTES RELATED
    notes: [],
    note: {},
    noteExists: false,
    matchNotes: [],
    confirmBoxNotes: false,
    deleteNoteObj: {},

    // TABLE RELATED
    // itemsTotal: 0,
    // itemsTo: 0,
    displayedCount: 0,
    tableHeaders: [
      {
        text: "Business",
        value: "businessTitle",
        align: "start",
        hidden: false,
        sortable: false,
        shrunk: true,
      },
      {
        text: "Type",
        value: "businessTypes",
        align: "start",
        hidden: false,
        sortable: false,
        shrunk: true,
      },
      {
        text: "Match Percentage",
        value: "matchPercent",
        align: "start",
        hidden: false,
        sortable: false,
        shrunk: true,
      },
      {
        text: "Status",
        value: "status",
        align: "start",
        hidden: false,
        sortable: false,
        shrunk: true,
      },
      {
        text: "",
        value: "action",
        align: "center",
        hidden: false,
        sortable: false,
        width: "110px",
        shrunk: true,
      },
    ],
    tableOptions: {
      page: 1,
      itemsPerPage: 20,
      sortBy: ["businessTitle"],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
    },
    matches: [],
    matchReport: [],

    // SEND MATCHES DIALOG
    sendMatchesDialog: false,
    customMessage: "",
    dialogLoader: false,
    fullMatchesArray: [],
    currentMatchType: "auto",
  }),

  // computed
  computed: {
    itemsFrom() {
      return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
    },
    itemsTo() {
      if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
        return this.itemsTotal;
      } else {
        return this.tableOptions.page * this.tableOptions.itemsPerPage;
      }
    },
    itemsCount() {
      return this.tableOptions.itemsPerPage;
    },
    // Calculate total for active tab only
    autoMatchesTotal() {
      return this.filteredMatchesByStatus.filter(match => match.matched === "auto").length;
    },
    
    manualMatchesTotal() {
      return this.filteredMatchesByStatus.filter(match => match.matched === "manual").length;
    },
    
    // Override itemsTotal to be tab-specific
    itemsTotal() {
      return this.matchTab === "auto" ? this.autoMatchesTotal : this.manualMatchesTotal;
    },
    // itemsTo() {
    //   return this.displayedAutoMatches.length;
    // },
    // limits the number of matches displayed for auto matches
    displayedAutoMatches() {
      const autoMatches = this.filteredMatchesByStatus.filter(match => match.matched === "auto");
      const start = this.itemsFrom;
      const end = start + this.tableOptions.itemsPerPage;
      return autoMatches.slice(start, end);
    },

    // limits the number of matches displayed for manual matches
    displayedManualMatches() {
      const manualMatches = this.filteredMatchesByStatus.filter(match => match.matched === "manual");
      const start = this.itemsFrom;
      const end = start + this.tableOptions.itemsPerPage;
      return manualMatches.slice(start, end);
    },
    ...mapGetters({
      GET_currentUserProfile: "GET_currentUserProfile",
    }),
    filteredHeaders() {
      let headers = this.tableHeaders.filter((header) => !header.hidden);
      if (this.drawer) {
        headers = headers.filter((header) => header.shrunk);
      }
      return headers;
    },
    filteredMatchesByStatus() {
      if (JSON.stringify(this.matchStatus) !== "[]") {
        return this.matches.filter((match) => this.matchStatus.includes(match.status));
      } else {
        return this.matches;
      }
    },
    computedBusiness() {
      return [
        {
          text: "Turnover",
          value:
            this.business.turnover !== 0 ? this.MIX_formatCurrency(this.business.turnover, 0) : "-",
        },
        {
          text: "Profit",
          value:
            this.business.profit !== 0 ? this.MIX_formatCurrency(this.business.profit, 0) : "-",
        },
        {
          text: "EBITDA",
          value:
            this.business.ebitda !== 0 ? this.MIX_formatCurrency(this.business.ebitda, 0) : "-",
        },
        {
          text: "Asking Price",
          value: this.business.price !== 0 ? this.MIX_formatCurrency(this.business.price, 0) : "-",
        },
        {
          text: "Financial Year",
          value: this.business.financialYear,
        },
        {
          text: "Source",
          value: this.business.sourceName,
          link: this.business.sourceURL,
        },
        {
          text: "Ref",
          value: this.business.ref,
        },
        {
          text: "Listing Date",
          value: this.MIX_formatDateTime(this.business.listingDateTime, "ISOString", "DD MMM YYYY"),
        },
        {
          text: "Last Seen",
          value: this.MIX_formatDateTime(
            this.business.lastSeenDateTime,
            "ISOString",
            "DD MMM YYYY"
          ),
        },
      ];
    },

    computedClientId() {
      return this.$route.params.id;
    },
  },

  // methods
  methods: {
    next() {
      if (this.tableOptions.page * this.tableOptions.itemsPerPage < this.itemsTotal) {
        this.tableOptions.page++;
        this.refreshMatchesList();
      }
    },
    // Previous page of the table
    previous() {
      if (this.tableOptions.page > 1) {
        this.tableOptions.page--;
        this.refreshMatchesList();
      }
    },
    openSendMatchesDialog(matchType) {
      this.currentMatchType = matchType;
      this.sendMatchesDialog = true;
    },
    countMatchesByStatus(status) {
      return this.matches.filter((match) => match.status === status).length;
    },
    // Method to copy text to clipboard
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            this.MIX_alertBox({
              show: true,
              message: "Summary copied to clipboard",
              color: "success",
              timeout: "2000",
            });
          })
          .catch((err) => {
            console.error("Could not copy text: ", err);
            this.MIX_alertBox({
              show: true,
              message: "Failed to copy to clipboard",
              color: "error",
              timeout: "2000",
            });
          });
      } else {
        // Fallback for older browsers
        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();

        try {
          const successful = document.execCommand("copy");
          if (successful) {
            this.MIX_alertBox({
              show: true,
              message: "Summary copied to clipboard",
              color: "success",
              timeout: "2000",
            });
          } else {
            this.MIX_alertBox({
              show: true,
              message: "Failed to copy to clipboard",
              color: "error",
              timeout: "2000",
            });
          }
        } catch (err) {
          console.error("Could not copy text: ", err);
          this.MIX_alertBox({
            show: true,
            message: "Failed to copy to clipboard",
            color: "error",
            timeout: "2000",
          });
        }

        document.body.removeChild(textarea);
      }
    },
    // loads more items for the matches table
    loadMore() {
      this.displayedCount += 10;
    },
    // confirmation before deleting notes
    deleteNote(note) {
      this.deleteNoteObj = note;
      this.action = "Delete";
      this.confirmBoxNotes = true;
    },
    // delete notes
    async confirmDeleteNote() {
      this.confirmBoxNotes = false;
      this.loading = true;
      await this.REDIS_delete("note", this.deleteNoteObj.entityId, {
        modifiedUserId: this.GET_currentUserProfile.entityId,
        modifiedDateTime: this.GET_currentUserProfile.entityId,
        deletedUserId: this.GET_currentUserProfile.entityId,
        deletedDateTime: this.GET_currentUserProfile.entityId,
        deleted: true,
      });
      this.MIX_alertBox({ show: true, message: "Note Deleted", color: "success", timeout: "2000" });
      this.deleteNoteObj = {};
      this.readnotes("client");
      this.readnotes("orgMatch");
      this.loading = false;
    },
    // edit notes
    async editNotes(note) {
      try {
        this.loading = true;
        // console.log("editNote", note);
        note.modifiedUserId = this.GET_currentUserProfile.entityId;
        note.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
        // console.log("editnote=", JSON.stringify(note, null, 2));
        await this.REDIS_update("note", note.entityId, note);
        this.MIX_alertBox({ show: true, message: "Note Saved", color: "success", timeout: "2000" });
        this.loading = false;
      } catch (error) {
        console.error("error", error);
        this.MIX_alertBox({
          show: true,
          message: "Error Saving Note",
          color: "error",
          timeout: "2000",
        });
      }
    },
    // upate notes prop
    updateNotesProp(value, id) {
      // console.log("updateNotesProp", value);
      if (id === "client") {
        this.noteFormMode = value;
      } else {
        this.matchNoteFormMode = value;
      }
    },
    // save new note
    async saveNewNote(newNote, section) {
      try {
        let validationErrors = 0;
        if (newNote.trim() === "") {
          validationErrors++;
        }

        if (validationErrors > 0) {
          this.MIX_alertBox({
            show: true,
            message: "Notes cannot be left blank",
            color: "error",
            timeout: "2000",
          });
          return;
        } else {
          this.loading = true;
          this.note = { ...this.$schema.note };
          // console.log("saveNewNote", newNote, section);
          if (section === "orgMatch") {
            this.note.matchId = this.match.id;
            this.note.businessId = this.business.id;
          }
          this.note.message = newNote;
          this.note.orgId = this.org.id;
          this.note.orgName = this.org.name;
          this.note.relatedSection = section;
          this.note.createdName =
            this.GET_currentUserProfile.firstName + " " + this.GET_currentUserProfile.lastName;
          this.note.createdUserId = this.GET_currentUserProfile.entityId;
          this.note.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
          this.note.modifiedUserId = this.GET_currentUserProfile.entityId;
          this.note.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
          // console.log("addnote=", this.note);
          await this.REDIS_create("note", this.note);
          this.MIX_alertBox({
            show: true,
            message: "Note Saved",
            color: "success",
            timeout: "2000",
          });
          this.noteFormMode = "read";
          this.matchNoteFormMode = "read";
          this.readnotes(section);
          this.loading = false;
        }
      } catch (error) {
        console.error("error", error);
        this.MIX_alertBox({
          show: true,
          message: "Error Saving Note",
          color: "error",
          timeout: "2000",
        });
      }
    },
    // reads notes
    async readnotes(section) {
      let searchQuery = `@deleted:{false} @relatedSection:{${section}} @orgId:{${this.$route.params.id}}`;
      if (section === "orgMatch") {
        searchQuery += `@matchId:{${this.match.id}} @businessId:{${this.business.id}}`;
      }
      let noteResult = await this.REDIS_searchFor(
        "note",
        "0",
        "",
        "createdDateTime",
        "desc",
        searchQuery
      );

      if (section === "client") {
        if (noteResult.data.total > 0) {
          this.notes = noteResult.data.documents;
        } else {
          this.notes = [];
        }
      }
      if (section === "orgMatch") {
        if (noteResult.data.total > 0) {
          this.matchNotes = noteResult.data.documents;
        } else {
          this.matchNotes = [];
        }
      }
    },

    // Initialise
    async initialise() {
      this.loading = true;

      // Get Organisation and profile if not new
      if (this.$route.params.id !== "new") {
        let orgResult = await this.REDIS_read("org", this.$route.params.id);
        this.org = orgResult.data;
        this.orgTypeString = this.org.type[0];
        this.orgEngagedString = this.org.engaged;
        this.orgTab = this.$route.params.orgTab;

        let orgProfileResult = await this.REDIS_searchFor(
          "orgprofile",
          "",
          "",
          "",
          "",
          `@deleted:{false} @orgId:{${this.$route.params.id}}`
        );
        if (orgProfileResult.data.total === 0) {
          this.orgprofile = { ...this.$schema.orgprofile };
          this.orgProfileExists = false;
        } else {
          this.orgProfileExists = true;
          this.orgprofile = orgProfileResult.data.documents[0];
          // fields for form
          if (this.orgprofile.inRegion.length > 0) {
            this.orgProfileRegionString = this.orgprofile.inRegion;
          }
          if (this.orgprofile.inOwnership.length > 0) {
            this.orgProfileOwnershipString = this.orgprofile.inOwnership;
          }
          if (this.orgprofile.type.length > 0) {
            this.orgProfileTypeString = this.orgprofile.type;
          }
          if (this.orgprofile.inCountry.length > 0) {
            this.orgProfileCountryString = this.orgprofile.inCountry;
          }
          if (this.orgprofile.inSectors.length > 0) {
            this.orgProfileSectorsString = this.orgprofile.inSectors;
          }
          if (this.orgprofile.inSubSectors.length > 0) {
            this.orgProfileSubSectorsString = this.orgprofile.inSubSectors;
          }
          // keywords
          const items = this.orgprofile.inKeywords.filter((item) => item !== "");
          this.keywordsString = items.join(", ");
        }
        // Fetching related Notes
        let noteResult = await this.REDIS_searchFor(
          "note",
          "0",
          "",
          "createdDateTime",
          "desc",
          `@deleted:{false} @relatedSection:{client} @orgId:{${this.$route.params.id}}`
        );
        if (noteResult.data.total === 0) {
          this.noteFormMode = "write";
        } else {
          this.noteFormMode = "read";
          this.notes = noteResult.data.documents;
          // fields for form
        }
      } else {
        // Get Organisation and profile if new
        this.orgprofile = { ...this.$schema.orgprofile };
        this.org = { ...this.$schema.org };
        // this.notes = { ...this.$schema.notes };
        this.orgprofile.enabled = true;
        this.orgFormMode = "add";
        this.orgProfileFormMode = "add";
      }

      // user list
      let dataResult = await this.REDIS_searchFor(
        "user",
        "",
        "",
        "",
        "",
        "@deleted:{false} @level:{staff|director|manager|administrator} @status:{approved}"
      );
      this.users = dataResult.data.documents;
      for (const user in this.users) {
        this.usersName.push(this.users[user].firstName + " " + this.users[user].lastName);
      }

      // DEFAULT USER FOR ACC OWNER
      if (this.$route.params.id === "new") {
        let user = this.users.filter(
          (item) => item.entityId === this.GET_currentUserProfile.entityId
        );
        this.org.ownerUserId = user[0].firstName + " " + user[0].lastName;
      } else {
        let index = await this.users.findIndex((x) => x.entityId === this.org.ownerUserId);
        if (index !== -1) {
          let currentUser = this.users[index];
          // console.log('currentUser', JSON.stringify(currentUser, null, 2))
          this.org.ownerUserId = currentUser.firstName + " " + currentUser.lastName;
        }
      }

      // Ignore Keywords List
      let ignorekeywordlistResult = await this.REDIS_getIgnoreKeywordList();
      this.ignorekeywordlist = ignorekeywordlistResult.data;

      // Lookups
      let lookupResult = await this.REDIS_searchFor(
        "lookup",
        "",
        "",
        "text",
        "asc",
        "@deleted:{false} @type:{county|country|city|region|organisation_type|business_type|organisation_status|sector|subsector|business_ownership|organisation_engagement|nda_status|match_status|business_type}"
      );
      this.lookups = lookupResult.data.documents;
      // let matchStatus = lookupResult.data.documents.filter((item) => item.type === "match_status");
      this.locations = lookupResult.data.documents.filter(
        (item) =>
          item.type === "county" ||
          item.type === "country" ||
          item.type === "city" ||
          item.type === "region"
      );
      this.regions = lookupResult.data.documents.filter((item) => item.type === "region");
      this.sectors = lookupResult.data.documents.filter((item) => item.type === "sector");
      this.subsectors = lookupResult.data.documents.filter((item) => item.type === "subsector");
      this.ownership = lookupResult.data.documents.filter(
        (item) => item.type === "business_ownership"
      );
      this.businessType = lookupResult.data.documents.filter(
        (item) => item.type === "business_type"
      );
      this.orgTypes = lookupResult.data.documents.filter(
        (item) => item.type === "organisation_type"
      );
      this.orgStatus = lookupResult.data.documents.filter(
        (item) => item.type === "organisation_status"
      );
      this.countries = lookupResult.data.documents.filter((item) => item.type === "country");
      this.orgEngaged = lookupResult.data.documents.filter(
        (item) => item.type === "organisation_engagement"
      );
      this.ndaStatus = lookupResult.data.documents.filter((item) => item.type === "nda_status");
      this.businessTypes = lookupResult.data.documents.filter(
        (item) => item.type === "business_type"
      );
      
      // Matches
      if (this.$route.params.id !== "new") {
        let matchResult = await this.REDIS_searchFor(
          "match",
          "0",
          this.itemsCount.toString(),
          "matchPercent",
          "desc",
          `@deleted:{false} @orgId:{${this.$route.params.id}}`
        );
        
        // Filter out matches with missing business data
        const rawMatches = matchResult.data.documents;
        const validMatches = [];
        const filteredMatchIds = [];
        
        // Process each match to check if business data exists
        for (const match of rawMatches) {
          try {
            // Attempt to fetch the business data
            let businessResult = await this.REDIS_read("business", match.businessId);
            
            // Only include matches with valid business data
            if (businessResult && businessResult.data && Object.keys(businessResult.data).length > 0) {
              validMatches.push(match);
            } else {
              filteredMatchIds.push(match.entityId);
              console.warn(`Filtered out match with ID ${match.entityId} - business data missing for ${match.businessId}`);
            }
          } catch (error) {
            filteredMatchIds.push(match.entityId);
            console.error(`Error checking business data for match ${match.entityId}:`, error);
          }
        }
        
        // Update the matches array with only valid matches
        this.matches = validMatches;
        this.itemsTotal = validMatches.length;
        this.displayedCount = this.itemsTotal;
        
        // Log information about filtered matches
        if (filteredMatchIds.length > 0) {
          console.info(`Filtered out ${filteredMatchIds.length} matches with missing business data`);
        }
      }
      
      this.loading = false;
    },
    // get user name
    async userName() {
      // DEFAULT USER FOR ACC OWNER
      if (this.$route.params.id === "new") {
        let user = this.users.filter(
          (item) => item.entityId === this.GET_currentUserProfile.entityId
        );
        // console.log("user", user);
        this.org.ownerUserId = user[0].firstName + " " + user[0].lastName;
      } else {
        let index = await this.users.findIndex((x) => x.entityId === this.org.ownerUserId);
        // console.log('index', index)
        if (index !== -1) {
          let currentUser = this.users[index];
          // console.log('currentUser', JSON.stringify(currentUser, null, 2))
          this.org.ownerUserId = currentUser.firstName + " " + currentUser.lastName;
        }
      }
    },
    // Update Table Options
    updateTableOptions(options) {
      this.tableOptions = options;
    },
    async openBusiness(item) {
      let businessResult = await this.REDIS_read("business", item.businessId);
      this.business = businessResult.data;
      this.match = item;
      if (this.match !== null && this.match !== undefined && this.match !== {}) {
        if (
          this.match.matchReport !== null &&
          this.match.matchReport !== undefined &&
          this.match.matchReport !== ""
        ) {
          let match = JSON.parse(this.match.matchReport);
          // loop through match object and if value is an array or not empty quote or null then add to new array as text and value
          // console.log('match', JSON.stringify(match, null, 2))
          const matchReport = [];

          for (const key in match) {
            // console.log(match[key])
            const value = match[key];

            if (JSON.stringify(value) !== "[]" && value !== "" && value !== null) {
              matchReport.push({ text: key, value: "Matched" });
            }
          }
          // console.log('matchReport', JSON.stringify(matchReport, null, 2))
          this.matchReport = matchReport;
        }
        // Fetching related Match Notes
        this.matchNotes = [];
        let searchQuery = `@deleted:{false} @relatedSection:{orgMatch} @orgId:{${this.$route.params.id}} @businessId:{${this.business.id}} @matchId:{${this.match.id}}`;
        let result = await this.REDIS_searchFor(
          "note",
          "0",
          "",
          "createdDateTime",
          "desc",
          searchQuery
        );
        let noteMatchResult = result.data.documents;
        if (result.data.total === 0 || noteMatchResult === undefined || noteMatchResult === null) {
          this.matchNoteFormMode = "write";
          // console.log("noteMatchResult", this.noteMatchResult, this.matchNoteFormMode);
        } else {
          this.matchNoteFormMode = "read";
          this.matchNotes = noteMatchResult;
          // console.log("noteMatchResult", this.notes, this.matchNoteFormMode);
        }
      }
      this.businessDrawer = true;
    },
    deleteMatch() {
      console.log("delete");
    },
    editMatch() {
      console.log("edit");
    },
    restoreMatch() {
      console.log("restore");
    },

    // Edit Org
    editAction(section) {
      switch (section) {
        case "org":
          this.orgFormMode = "edit";
          break;
        case "orgProfile":
          this.orgProfileFormMode = "edit";
          break;
        default:
          return "";
      }
    },

    // cancel actions
    closeUser(section) {
      if (this.orgProfileFormMode === "add" || this.orgFormMode === "add") {
        this.$router.push({ name: "ClientsView" });
      } else if (section === "org" && this.orgFormMode !== "add") {
        this.orgFormMode = "";
      } else {
        this.orgProfileFormMode = "";
      }
    },

    // Save Organisation Profile to Redis
    async saveOrgProfile() {
      try {
        this.loading = true;
        // validation
        let validationErrors = 0;
        if (this.org.id === "") {
          validationErrors++;
        }

        if (
          this.orgprofile.turnoverMin &&
          this.orgprofile.turnoverMax &&
          parseInt(this.orgprofile.turnoverMin) > parseInt(this.orgprofile.turnoverMax)
        ) {
          this.validate.turnoverMin = "Turnover Min cannot be higher than Turnover Max";
          this.validate.turnoverMax = "Turnover Max cannot be lower than Turnover Min";
          validationErrors++;
        }

        if (
          this.orgprofile.priceMin &&
          this.orgprofile.priceMax &&
          parseInt(this.orgprofile.priceMin) > parseInt(this.orgprofile.priceMax)
        ) {
          this.validate.priceMin = "Price Min cannot be higher than Price Max";
          this.validate.priceMax = "Price Max cannot be lower than Price Min";
          validationErrors++;
        }

        if (
          this.orgprofile.ebitdaMin &&
          this.orgprofile.ebitdaMax &&
          parseInt(this.orgprofile.ebitdaMin) > parseInt(this.orgprofile.ebitdaMax)
        ) {
          this.validate.ebitdaMin = "EBITDA Min cannot be higher than EBITDA Max";
          this.validate.ebitdaMax = "EBITDA Max cannot be lower than EBITDA Min";
          validationErrors++;
        }

        if (
          this.orgprofile.profitMin &&
          this.orgprofile.profitMax &&
          parseInt(this.orgprofile.profitMin) > parseInt(this.orgprofile.profitMax)
        ) {
          this.validate.profitMin = "Profit Min cannot be higher than Profit Max";
          this.validate.profitMax = "Profit Max cannot be lower than Profit Min";
          validationErrors++;
        }

        // if no validation errors
        if (validationErrors === 0) {
          this.orgprofile.orgId = this.org.id;
          this.orgprofile.orgName = this.org.name;
          this.orgprofile.priceMin = parseInt(this.orgprofile.priceMin);
          this.orgprofile.priceMax = parseInt(this.orgprofile.priceMax);
          this.orgprofile.turnoverMin = parseInt(this.orgprofile.turnoverMin);
          this.orgprofile.turnoverMax = parseInt(this.orgprofile.turnoverMax);
          this.orgprofile.ebitdaMin = parseInt(this.orgprofile.ebitdaMin);
          this.orgprofile.ebitdaMax = parseInt(this.orgprofile.ebitdaMax);
          this.orgprofile.profitMin = parseInt(this.orgprofile.profitMin);
          this.orgprofile.profitMax = parseInt(this.orgprofile.profitMax);
          this.orgprofile.type = this.orgProfileTypeString ? this.orgProfileTypeString : [];
          this.orgprofile.ownerUserId = this.org.ownerUserId;
          this.orgprofile.inOwnership = this.orgProfileOwnershipString
            ? this.orgProfileOwnershipString
            : [];
          this.orgprofile.inRegion = this.orgProfileRegionString ? this.orgProfileRegionString : [];
          this.orgprofile.inCountry = this.orgProfileCountryString
            ? this.orgProfileCountryString
            : [];
          this.orgprofile.inSectors = this.orgProfileSectorsString
            ? this.orgProfileSectorsString
            : [];
          this.orgprofile.inSubSectors = this.orgProfileSubSectorsString
            ? this.orgProfileSubSectorsString
            : [];
          this.orgprofile.inKeywords = this.keywordsString
            .split(",")
            .map((item) => item.trim().toLowerCase().replace(".", ""))
            .filter((item) => item !== "");

          // add new org profile
          if (this.orgProfileFormMode === "add" || this.orgProfileExists === false) {
            this.orgprofile.createdUserId = this.GET_currentUserProfile.entityId;
            this.orgprofile.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
            this.orgprofile.modifiedUserId = this.GET_currentUserProfile.entityId;
            this.orgprofile.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
            // console.log("addOrgProfile=", JSON.stringify(this.orgprofile, null, 2));
            await this.REDIS_create("orgprofile", this.orgprofile);
            this.MIX_alertBox({
              show: true,
              message: "Client Profile Saved",
              color: "success",
              timeout: "2000",
            });
            this.orgProfileExists = true;
            this.loading = false;
            window.location.reload();
            // edit org profile
          } else if (this.orgProfileFormMode === "edit" && this.orgProfileExists === true) {
            this.orgprofile.modifiedUserId = this.GET_currentUserProfile.entityId;
            this.orgprofile.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
            // console.log("editOrgProfile=", JSON.stringify(this.orgprofile, null, 2));
            await this.REDIS_update("orgprofile", this.orgprofile.entityId, this.orgprofile);
            this.MIX_alertBox({
              show: true,
              message: "Client Profile Saved",
              color: "success",
              timeout: "2000",
            });
            this.orgProfileFormMode = "";
            this.loading = false;
          }
        } else {
          // runs if there are validaion errors
          this.MIX_alertBox({
            show: true,
            message: "Error Saving Client Criteria",
            color: "error",
            timeout: "4000",
          });
          this.loading = false;
        }
      } catch (error) {
        this.MIX_alertBox({
          show: true,
          message: "Error Saving Client Profile",
          color: "error",
          timeout: "4000",
        });
        console.error("error", error);
        this.loading = false;
      }
    },

    // Saves org details to redis
    async saveOrg() {
      try {
        this.loading = true;
        // validation
        let validationErrors = 0;
        if (this.orgTypeString.length === 0) {
          this.validate.orgTypeString = "Type is required";
          validationErrors++;
        }
        if (this.orgEngagedString === "") {
          this.validate.orgEngagedString = "Engaged is required";
          validationErrors++;
        }
        if (this.org.contactFirstName.trim() === "") {
          this.validate.contactFirstName = "First Name is required";
          validationErrors++;
        }
        if (this.org.contactLastName.trim() === "") {
          this.validate.contactLastName = "Last Name is required";
          validationErrors++;
        }
        if (this.org.telephone) {
          if (!/^\+?\d{1,}$/g.test(this.org.telephone)) {
            this.validate.telephone = "Telephone must be valid";
            validationErrors++;
          }
        }
        if (this.org.telephone) {
          if (this.org.telephone.length < 7) {
            this.validate.telephone = "Please enter a minimum of 7 digits";
            validationErrors++;
          }
        }
        if (this.org.email === "") {
          this.validate.email = "Email is required";
          validationErrors++;
        } else if (!/.+@.+\..+/.test(this.org.email)) {
          this.validate.email = "E-mail must be valid";
          validationErrors++;
        }

        if (this.org.postcode) {
          if (!/^[A-Za-z0-9\s]{3,10}$/.test(this.org.postcode)) {
            this.validate.postcode =
              "Postal code must be alphanumeric and between 3 to 10 characters";
            validationErrors++;
          }
        }

        if (this.org.ownerUserId === "") {
          this.validate.ownerUserId = "Account Owner is required";
          validationErrors++;
        }
        if (this.org.status === "") {
          this.validate.status = "Status is required";
          validationErrors++;
        }

        // loop through the this.org object and trim all the values
        Object.keys(this.org).forEach((key) => {
          if (typeof this.org[key] === "string") {
            this.org[key] = this.org[key].trim();
            // remove any . from the end of the string
            if (this.org[key].slice(-1) === ".") {
              this.org[key] = this.org[key].slice(0, -1);
            }
          }
        });

        // If no validation errors
        if (validationErrors === 0) {
          // this.showAccount = false;
          this.org.type = [this.orgTypeString];
          this.org.engaged = this.orgEngagedString;

          // add new org
          if (this.orgFormMode === "add") {
            // org id
            this.org.id = this.MIX_generateId();

            // auditing data
            let ownerUserId = this.users.filter(
              (item) => item.firstName + " " + item.lastName === `${this.org.ownerUserId}`
            )[0].entityId;
            this.org.ownerUserId = ownerUserId;
            this.org.createdUserId = this.GET_currentUserProfile.entityId;
            this.org.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
            this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
            this.org.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
            // console.log("addorg=", JSON.stringify(this.org, null, 2));
            await this.REDIS_createWithId("org", this.org, this.org.id);
            this.orgFormMode = "";
            this.$router.push({ name: "ClientView", params: { id: this.org.id } });
            this.loading = false;
            this.MIX_alertBox({
              show: true,
              message: "Client Details Saved",
              color: "success",
              timeout: "2000",
            });
          } else if (this.orgFormMode === "edit") {
            // auditing data
            // console.log("this.org.ownerUserId=", this.org.ownerUserId);
            let ownerUserId = this.users.filter(
              (item) => item.firstName + " " + item.lastName === `${this.org.ownerUserId}`
            )[0].entityId;
            if (ownerUserId === undefined || ownerUserId === null || ownerUserId === "") {
              ownerUserId = this.org.ownerUserId;
            }
            this.org.ownerUserId = ownerUserId;
            this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
            this.org.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
            // console.log("editorg=", JSON.stringify(this.org, null, 2));
            await this.REDIS_update("org", this.org.id, this.org);
            await this.userName();
            this.orgFormMode = "";
            this.loading = false;
            this.MIX_alertBox({
              show: true,
              message: "Client Details Saved",
              color: "success",
              timeout: "2000",
            });
          }
        } else {
          this.loading = false;
          this.MIX_alertBox({
            show: true,
            message: "Error Saving Client Details.",
            color: "error",
            timeout: "4000",
          });
        }
      } catch (error) {
        this.MIX_alertBox({
          show: true,
          message: "Error Saving Client Details",
          color: "error",
          timeout: "4000",
        });
        console.error("error", error);
        this.loading = false;
      }
    },

    filterIgnoreList(keywords) {
      // if any of the keywords are in the ignoreList then filter them out
      const result = [];
      for (let i = 0; i < keywords.length; i++) {
        if (!this.ignorekeywordlist.includes(keywords[i])) {
          result.push(keywords[i]);
        }
      }
      return result;
    },
    lookupValue(value) {
      if (value !== "") {
        let lookup = this.lookups.find((item) => item.value === value);
        if (lookup !== undefined) {
          return lookup.text;
        } else {
          return "";
        }
      }
    },
    async updateMatch(status) {
      this.match.status = status;
      // console.log("this.match", status);
      if (this.match.status == "approved") {
        this.match.reason = "";
      }
      this.refreshMatchesList();
    },

    async confirmMatch() {
      try {
        // Validation
        if (this.match.reason === "" && this.match.status === "rejected") {
          this.MIX_alertBox({
            show: true,
            message: "A Rejection Reason must be added.",
            color: "error",
            timeout: "4000",
          });
          return;
        }

        // Step 1: Update the match status in the database
        this.match.modifiedUserId = this.GET_currentUserProfile.entityId;
        this.match.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");

        await this.REDIS_update("match", this.match.entityId, this.match);

        // Step 2: Refresh matches first to get the latest data
        await this.refreshMatchesList();

        // Step 3: Ensure `this.filteredMatchesByStatus` is updated
        this.$forceUpdate(); // Manually trigger Vue reactivity if needed

        // Step 4: Now update the client's match count
        await this.updateClientMatchCount();

        // Show success message
        this.MIX_alertBox({
          show: true,
          message: "Match Updated",
          color: "success",
          timeout: "2000",
        });

        // Set the correct tab
        this.businessTabs = "status";
      } catch (error) {
        this.MIX_alertBox({
          show: true,
          message: "Error Updating Match",
          color: "error",
          timeout: "4000",
        });
        console.error(error);
      }
    },
    // Modify your refreshMatchesList method to filter out matches with missing business data
    async refreshMatchesList() {
      try {
        this.loading = true;

        // Use computed properties for pagination
        const offset = this.itemsFrom.toString();
        const limit = this.itemsCount.toString();

        // Fetch all matches for this client
        let matchResult = await this.REDIS_searchFor(
          "match",
          offset,
          limit,
          "matchPercent",
          "desc",
          `@deleted:{false} @orgId:{${this.$route.params.id}}`
        );

        // Store the raw matches before filtering
        const rawMatches = matchResult.data.documents;
        const validMatches = [];
        
        // Check each match for valid business data
        for (const match of rawMatches) {
          try {
            // Attempt to fetch the business data
            let businessResult = await this.REDIS_read("business", match.businessId);
            
            // Only include matches with valid business data
            if (businessResult && businessResult.data && Object.keys(businessResult.data).length > 0) {
              validMatches.push(match);
            } else {
              console.warn(`Filtered out match with ID ${match.entityId} - business data missing for ${match.businessId}`);
            }
          } catch (error) {
            console.error(`Error checking business data for match ${match.entityId}:`, error);
            // Skip this match due to the error
          }
        }

        // Update the matches array with only valid matches
        this.matches = validMatches;
        this.itemsTotal = validMatches.length;
        this.displayedCount = this.itemsTotal;
        
        // Log if any matches were filtered out
        if (rawMatches.length !== validMatches.length) {
          console.info(`Filtered out ${rawMatches.length - validMatches.length} matches with missing business data`);
        }
        
        this.loading = false;
      } catch (error) {
        console.error("Error refreshing matches list:", error);
        this.loading = false;
      }
    },

    // In ClientView.vue (updateClientMatchCount method)
    async updateClientMatchCount() {
      try {
        // Get the current client ID
        let orgId = this.$route.params.id;
        if (!orgId) return;

        // Step 1: Ensure latest match data is used
        await this.refreshMatchesList();

        // Step 2: Calculate total matches
        let totalMatches = this.matches.length;

        // Step 3: Count rejected matches
        let rejectedMatches = this.matches.filter((match) => match.status === "rejected").length;

        // Step 4: Calculate new match count (total - rejected)
        let newMatchCount = totalMatches - rejectedMatches;

        // console.log("Total Matches:", totalMatches);
        // console.log("Rejected Matches:", rejectedMatches);
        // console.log("New Match Count (Accepted + Pending):", newMatchCount);
        // console.log("Existing Org Match Count (from this.org.matchCount):", this.org.matchCount);

        // Step 5: Ensure matchCount is a valid number
        let currentMatchCount = Number(this.org.matchCount) || 0;

        // Step 6: Only update if the match count has changed
        if (currentMatchCount !== newMatchCount) {
          // Ensure Vue reactivity
          this.$set(this.org, "matchCount", newMatchCount);

          this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
          this.org.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");

          // console.log("Updating match count in Redis:", this.org);

          // Step 7: Save the updated organization data in Redis
          await this.REDIS_update("org", orgId, this.org);
        }

        // console.log(`Updated match count for ${orgId}: ${newMatchCount}`);
      } catch (error) {
        console.error("Error updating match count:", error);
      }
    },
    async saveStatus() {
      try {
        // validation
        let validationErrors = 0;
        if (this.match.enquiryStatus === "") {
          validationErrors++;
        }

        // if no validation errors
        if (validationErrors === 0) {
          this.match.modifiedUserId = this.GET_currentUserProfile.entityId;
          this.match.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");

          // Update the match
          await this.REDIS_update("match", this.match.entityId, this.match);

          // Refresh the matches list
          await this.refreshMatchesList();

          this.MIX_alertBox({
            show: true,
            message: "Match Updated",
            color: "success",
            timeout: "2000",
          });
        } else {
          // runs if there are validation errors
          this.MIX_alertBox({
            show: true,
            message: "A Status must be selected.",
            color: "error",
            timeout: "4000",
          });
          this.loading = false;
        }
      } catch (error) {
        this.MIX_alertBox({
          show: true,
          message: "Error Updating Match",
          color: "error",
          timeout: "4000",
        });
        console.error(error);
      }
    },

    async sendMatches() {
      try {
        // Get selected businesses from fullMatchesArray
        let selectedBusinesses = this.fullMatchesArray.filter(
          (business) => business.selected && business.selected === true
        );

        // Check if we have any selected businesses
        if (selectedBusinesses.length === 0) {
          this.MIX_alertBox({
            show: true,
            message: "Please select at least one business to send",
            color: "warning",
            timeout: "3000"
          });
          return;
        }

        let mailData = { ...this.$schema.mail };

        mailData.fromEmail = "deals@gsverde.group";
        mailData.fromName = "ARDeals - GSVerde";

        // mailData.toEmail = 'adam@vindico.net'; // ! Testing change before deployment
        mailData.toEmail = this.org.email;
        mailData.toName = this.org.contactFirstName + " " + this.org.contactLastName;

        let id = this.MIX_generateId();
        mailData.toOrgId = this.org.id;
        mailData.subject = `Selected ${
          this.currentMatchType === "auto" ? "Automatic" : "Manual"
        } Business Matches`;
        mailData.templateId = "d-b265b6649909482a91581d756a78c8c3";

        // Create the email data with consistent ID field usage
        let emailItems = selectedBusinesses.map((business) => {
          return {
            id: business.entityId, // Store the business ID for reference
            businessTitle: business.title,
            link: window.location.origin + "/match/" + business.entityId + "/" + id,
          };
        });

        mailData.data = emailItems;
        mailData.customMessage = this.customMessage;

        mailData.createdName =
          this.GET_currentUserProfile.firstName + " " + this.GET_currentUserProfile.lastName;
        mailData.createdUserId = this.GET_currentUserProfile.entityId;
        mailData.createdDateTime = this.MIX_formatDateTimeNow("toISOString");

        console.log("Sending email with the following businesses:", 
          selectedBusinesses.map(b => `${b.entityId} (${b.title})`));

        // Send the email
        await this.REDIS_sendEmail(mailData);

        // Store the email in the database
        mailData.id = id;
        mailData.data = JSON.stringify(emailItems); // Store the same data we sent
        await this.REDIS_createWithId("mail", mailData, mailData.id);

        // Keep track of any match updates that fail
        const failedUpdates = [];

        // Update the status of each match in the database
        for (const business of selectedBusinesses) {
          try {
            // Need to find the actual match for the org and business
            const matchCollection =
              this.currentMatchType === "auto"
                ? this.displayedAutoMatches
                : this.displayedManualMatches;
            
            // Find the match using businessId (equals the business entityId)
            let index = matchCollection.findIndex((match) => match.businessId === business.entityId);
            
            if (index !== -1) {
              let matchData = matchCollection[index];
              console.log(`Updating match status for business ${business.entityId} (${business.title}), match ID: ${matchData.entityId}`);
              
              // Update the match status
              matchData.enquiryStatus = "Match Shared with Client";
              await this.REDIS_update("match", matchData.entityId, matchData);
            } else {
              console.warn(`Could not find match for business ${business.entityId} (${business.title})`);
              failedUpdates.push(business.title);
            }
          } catch (error) {
            console.error(`Error updating match for business ${business.entityId}:`, error);
            failedUpdates.push(business.title);
          }
        }

        // Refresh the matches list to ensure UI is up to date
        await this.refreshMatchesList();
        
        // Close the dialog
        this.sendMatchesDialog = false;
        
        // Show success message, noting any failed updates
        if (failedUpdates.length === 0) {
          this.MIX_alertBox({ 
            show: true, 
            message: "Email Sent Successfully", 
            color: "success", 
            timeout: "2000" 
          });
        } else {
          this.MIX_alertBox({ 
            show: true, 
            message: `Email Sent Successfully, but ${failedUpdates.length} match updates failed`, 
            color: "warning", 
            timeout: "4000" 
          });
        }
      } catch (error) {
        console.error("Error sending matches:", error);
        this.MIX_alertBox({
          show: true,
          message: "Error Sending Email",
          color: "error",
          timeout: "4000",
        });
      }
    },
    async checkMail() {
      // get all details for items in either displayedAutoMatches or displayedManualMatches
      let array = [];
      
      // Get all matches of the current type regardless of UI filter selection
      const allMatchesOfCurrentType = this.matches.filter(match => 
        match.matched === this.currentMatchType && 
        match.status !== 'rejected' // Exclude rejected matches
      );
      
      // Use a reasonable limit if needed
      const matchesToProcess = allMatchesOfCurrentType.slice(0, 50); 

      for (const item of matchesToProcess) {
        let businessResult = await this.REDIS_read("business", item.businessId);
        let business = businessResult.data;
        business.matchPercent = item.matchPercent;

        // If manual matches, set selected to true by default
        if (this.currentMatchType === "manual") {
          business.selected = true;
        }

        array.push(business);
      }

      this.fullMatchesArray = array;

      // get all mail that has been sent to this org
      let searchQuery = `@deleted:{false} @toOrgId:{${this.org.id}}`;
      let mailResult = await this.REDIS_searchFor(
        "mail",
        "0",
        "",
        "createdDateTime",
        "asc",
        searchQuery
      );

      let results = mailResult.data.documents;
      if (mailResult.data.total > 0) {
        // go through mail and link to fullMatchesArray
        results.forEach((mail) => {
          mail.data = JSON.parse(mail.data);
          // for each match in mail.data
          mail.data.forEach((match) => {
            // find match in fullMatchesArray and if present, set 'sent' to true
            let index = this.fullMatchesArray.findIndex((x) => x.entityId === match.id);

            if (index !== -1) {
              this.fullMatchesArray[index].sent = true;
              this.fullMatchesArray[index].sentDateTime = mail.createdDateTime;

              if (
                mail.readDateTime !== null &&
                mail.readDateTime !== undefined &&
                mail.readDateTime !== ""
              ) {
                this.fullMatchesArray[index].read = true;
                this.fullMatchesArray[index].readDateTime = mail.readDateTime;
              }
            }
          });
        });
      }

      this.dialogLoader = false;
    },
  },

  // * watch
  watch: {
    sendMatchesDialog: {
      handler() {
        if (this.sendMatchesDialog === false) {
          this.customMessage = "";
          if (this.currentMatchType === "auto") {
            this.displayedAutoMatches.forEach((match) => {
              match.selected = null;
            });
          } else {
            this.displayedManualMatches.forEach((match) => {
              match.selected = null;
            });
          }
          this.fullMatchesArray = [];
        } else {
          this.dialogLoader = true;
          this.checkMail();
        }
      },
    },
    businessDrawer(newVal, oldVal) {
      if (!newVal && oldVal) {
        // Drawer is closing
        this.refreshMatchesList();
      }
    },
    matchTab() {
      // Reset to page 1 when switching tabs
      this.tableOptions.page = 1;
    },
  },

  // * CREATED
  created() {
    this.initialise();
  },
};
</script>

<style>
#clientData-table.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}
</style>
<style scoped>
.d-flex {
  gap: 15px;
}

/*click behaviour for mobile version match tables */
.pointer-hover {
  cursor: pointer;
}

.pointer-hover:hover {
  color: var(--v-primary-base);
  text-decoration: underline;
}

/* spacing for mobile version match tables */
.vertical-table .vertical-table-item {
  margin-bottom: 20px;
}

.vertical-table .spacer-cell {
  height: 20px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777777;
}
</style>
