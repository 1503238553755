import { mapGetters, mapActions } from "vuex";

const mixin = {
    data: () => ({
        apiUrl: process.env.VUE_APP_API_URL,
    }),
    computed: {
        ...mapGetters({
            GET_currentUserAuth: "GET_currentUserAuth",
        }),
        apiOptions() {
            return {
                headers: {
                    Authorization: `Bearer ${this.GET_currentUserAuth.accesstoken}`,
                },
            };
        },
    },
    methods: {
        ...mapActions({
            ACT_noCloud: "ACT_noCloud",
        }),
        REDIS_uploadCSV(file) {
            return new Promise((resolve, reject) => {
                (async () => {
                    if (!file) {
                        reject("No file selected");
                        return;
                    }

                    const formData = new FormData();
                    formData.append("file", file);

                    try {
                        const url = `${this.apiUrl}business/upload-csv`;
                        let result = await this.$axios.post(url, formData, {
                            headers: {
                                ...this.apiOptions.headers,
                                "Content-Type": "multipart/form-data"
                            }
                        });

                        if (result.status === 201) {
                            resolve({ success: true, data: result.data });
                        } else {
                            reject({ success: false, error: result.error });
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        REDIS_createNewUser(data, id) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}user/createnewuser/${id}`;
                        // console.log('url', url)
                        let result = await this.$axios.post(url, data, this.apiOptions);
                        if (result.status === 201) {
                            this.ACT_noCloud(false);
                            resolve({ success: true, data: result.data });
                        } else {
                            // this.ACT_noCloud(true);
                            reject({ success: false, error: result.error });
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // CREATE with ID
        REDIS_createWithId(collection, data, id) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/createwithid/${id}`;
                        // console.log('url', url)
                        let result = await this.$axios.post(url, data, this.apiOptions);
                        if (result.status === 201) {
                            this.ACT_noCloud(false);
                            resolve({ success: true, data: result.data });
                        } else {
                            // this.ACT_noCloud(true);
                            reject({ success: false, error: result.error });
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // CREATE JSON RECORD IN REDIS
        REDIS_create(collection, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/`;
                        // console.log('url', url)
                        let result = await this.$axios.post(url, data, this.apiOptions);
                        // console.log('result = ', result)
                        if (result.status === 201) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // TODO - START HERE
        // BULK CREATE JSON RECORD IN REDIS
        REDIS_bulkCreateUpdate(collection, data, progressId) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/bulkcreateupdate/?progressid=${progressId}`;
                        let result = await this.$axios.post(url, data, this.apiOptions);
                        if (result.status === 201) {
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // READ JSON RECORD FROM REDIS
        REDIS_read(collection, entityId) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.get(url);
                        if (result.status === 200) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else if (result.status === 204) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // UPDATE JSON RECORD IN REDIS
        REDIS_update(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.put(url, data);
                        if (result.status === 200) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // DELETE JSON RECORD IN REDIS BY MARKING AS DELETED
        REDIS_delete(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.put(url, data);
                        if (result.status === 200) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // RESTORE JSON RECORD IN REDIS BY REMOVING MARK AS DELETED
        REDIS_restore(collection, entityId, data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.put(url, data);
                        if (result.status === 200) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // DESTROY JSON RECORD IN REDIS BY REMOVING FROM REDIS
        REDIS_destroy(collection, entityId) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/${entityId}`;
                        let result = await this.$axios.delete(url);
                        if (result.status === 202) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // EXPIRE JSON RECORD IN REDIS BY SETTING TTL
        REDIS_expire(collection, entityId, expirySeconds) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/expire/${entityId}?seconds=${expirySeconds}`;
                        let result = await this.$axios.get(url);
                        if (result.status === 200) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL JSON RECORDS IN REDIS
        REDIS_searchAll(collection, offset, count, sortBy, sortOrder) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}${collection}/search/all?offset=${offset}&count=${count}&sortby=${sortBy}&sortorder=${sortOrder}`;
                        let result = await this.$axios.get(url);
                        if (result.status === 200) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        REDIS_searchByOrgs(offset, count, query) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}org/list/?offset=${offset}&count=${count}&query=${query}`;
                        // console.log(url);
                        let result = await this.$axios.get(url);
                        if (result.status === 200) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL JSON RECORDS FOR CLIENTS IN REDIS
        REDIS_searchByMatch(offset, count, query) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}org/orgByMatches/?offset=${offset}&count=${count}&query=${query}`;
                        // console.log(url);
                        let result = await this.$axios.get(url);
                        if (result.status === 200) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // RETURN ALL JSON RECORDS IN REDIS THAT MATCH QUERY
        REDIS_searchFor(collection, offset, count, sortBy, sortOrder, query) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        // console.log( { collection, offset, count, sortBy, sortOrder, query } );
                        const url = `${this.apiUrl}${collection}/search/for?offset=${offset}&count=${count}&sortby=${sortBy}&sortorder=${sortOrder}&query=${query}`;
                        // console.log(url);
                        let result = await this.$axios.get(url);
                        if (result.status === 200) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // RETURN COUNT IN REDIS THAT MATCH QUERY
        REDIS_countFor(collection, offset, count, sortBy, sortOrder, query) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        // console.log( { collection, offset, count, sortBy, sortOrder, query } );
                        const url = `${this.apiUrl}${collection}/count/for?offset=${offset}&count=${count}&sortby=${sortBy}&sortorder=${sortOrder}&query=${query}`;
                        // console.log(url)
                        let result = await this.$axios.get(url);
                        if (result.status === 200) {
                            this.ACT_noCloud(false);
                            resolve(result.data);
                        } else {
                            // this.ACT_noCloud(true);
                            reject(result.error);
                        }
                    } catch (error) {
                        // this.ACT_noCloud(true);
                        reject(error);
                    }
                })();
            });
        },
        // Add Keyword to Ignore List
        REDIS_addIgnoreKeyword(keyword) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}redis/ignorekeywordlist`;
                        let result = await this.$axios.post(url, { keyword: keyword });
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // Remove Keyword from Banned List
        REDIS_removeIgnoreKeyword(keyword) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}redis/ignorekeywordlist`;
                        let result = await this.$axios.delete(url, { keyword: keyword });
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        // Get Banned Keywords
        REDIS_getIgnoreKeywordList() {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}redis/ignorekeywordlist`;
                        let result = await this.$axios.get(url);
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        REDIS_sendEmail(data) {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        const url = `${this.apiUrl}mail/instant`;
                        let result = await this.$axios.post(url, { data: data });
                        if (result.status === 200) {
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                })();
            });
        },
        REDIS_recentMatches() {
            return new Promise((resolve, reject) => {
                (async () => {
                    try {
                        // Simplified request with minimal parameters
                        const url = `${this.apiUrl}match/mostRecentMatches`;
                        
                        let result = await this.$axios.get(url, this.apiOptions);
                        
                        if (result.status === 200) {
                            // If needed, filter results client-side to ensure only accepted/pending matches
                            if (result.data && result.data.data) {
                                // The backend should handle this filtering, but we can do it here as a safeguard
                                /*
                                result.data.data = result.data.data.map(item => {
                                    if (item.matchCount) {
                                        // Additional client-side filtering can be done here if necessary
                                    }
                                    return item;
                                });
                                */
                            }
                            resolve(result.data);
                        } else {
                            reject(result.error);
                        }
                    } catch (error) {
                        console.error("Error fetching recent matches:", error);
                        // Log more details about the error
                        if (error.response) {
                            console.error("Response data:", error.response.data);
                            console.error("Response status:", error.response.status);
                        }
                        reject(error);
                    }
                })();
            });
        }
    },
};

export default {
    install(Vue) {
        Vue.mixin(mixin);
    },
};
