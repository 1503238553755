<template>
  <div>
    <div style="height: 2px !important">
      <v-progress-linear
        :indeterminate="loading"
        :color="loading ? 'primary' : 'grey lighten-2'"
        :value="!loading ? 100 : 0"
      ></v-progress-linear>
    </div>

    <!-- OVERLAY -->

    <div v-if="filterDrawer" class="leftside-overlay" @click="filterDrawer = false"></div>
    <!-- MAIN PAGE -->
    <v-row dense class="px-5 pt-5">
      <v-container v-if="loading" class="loader-overlay" style="z-index: 9999">
        <v-progress-circular
          :indeterminate="loading"
          class="text-center"
          size="108"
          :color="loading ? 'primary' : 'grey lighten-2'"
          :value="!loading ? 100 : 0"
          >{{ "Loading Clients" }}</v-progress-circular
        >
      </v-container>
      <v-col
        cols="12"
        :class="$vuetify.breakpoint.width < 600 ? '' : 'd-flex justify-space-between'"
      >
        <div class="">
          <div class="d-flex align-center" style="padding-left: 10px">
            <div>
              <div class="text-h4 primary--text d-flex flex-column pb-2">
                Matches
                <div class="d-flex align-center pb-2" style="width: 100% !important">
                  <div class="body-2 grey--text text--darken-2">
                    Showing <strong>{{ itemsFrom + 1 }}</strong>
                    <span v-if="itemsTo !== itemsTotal">
                      to <strong>{{ itemsTo }}</strong></span
                    >
                    of <strong>{{ itemsTotal }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-spacer />
        </div>
        <div
          :class="
            $vuetify.breakpoint.width >= 600 && $vuetify.breakpoint.width < 880
              ? `d-flex justify-end`
              : $vuetify.breakpoint.width < 600
              ? `d-flex flex-wrap align-start justify-space-evenly `
              : 'd-flex'
          "
          :style="$vuetify.breakpoint.width < 600 ? 'padding-left: 10px;' : 'padding-right: 10px;'"
        >
          <AppButton @click.native="MIX_go('/match-cleanup')" title="Match Cleanup"
            ><v-icon class="error--text" small>icons8-trash-can</v-icon
            ><span class="ml-1">Match Cleanup</span></AppButton
          >
          <AppButton @click.native="seeFavourites = !seeFavourites" title="Favourites"
            ><v-icon class="gold--text">icons8-rating</v-icon></AppButton
          >
          <AppButton @click.native="getTableData" title="Refresh"
            ><v-icon>icons8-synchronize</v-icon></AppButton
          >
          <AppButton v-if="!table" @click.native="table = true" title="Switch to table"
            ><v-icon class="primary--text">icons8-rows</v-icon></AppButton
          >
          <AppButton v-if="table" @click.native="table = false" title="Switch to grid"
            ><v-icon class="primary--text">icons8-table</v-icon></AppButton
          >
        </div>
      </v-col>
      <v-col cols="12" class="pt-2" no-gutters>
        <!-- Data Table :matches="matchCount"-->
        <DataTable
          v-if="table && tableData.length > 0"
          :tableheaders="filteredHeaders"
          :actions="['Edit']"
          :loading="loading"
          custom="orgs"
          :tabledata="tableData"
          :datatableoptions="tableOptions"
          :enabledelete="enableDelete"
          @star="starItem"
          @viewmatch="openMatches"
          :itemstotal="itemsTotal"
          v-on:delete="deleteOrg"
          v-on:edit="editOrg"
          v-on:restore="restoreOrg"
          v-on:tableoptions="updateTableOptions"
          :lookups="lookups"
        />
        <div v-if="!table && tableData.length > 0" class="business-cards-container">
          <div class="business-cards" v-for="item in tableData" :key="item.entityId">
            <BasicCard class="business-card-component">
              <template v-slot:header>
                <div class="full-width d-flex align-start px-3 pt-2">
                  <div
                    class="text-body-1 font-weight-bold primary--text text-left"
                    @click="editOrg(item)"
                  >
                    {{ item.firstName }} {{ item.lastName }}<br />
                    <span class="secondary--text">{{ item.companyName || "N/A" }}</span>
                  </div>
                  <v-spacer />
                  <v-icon
                    v-if="enableDelete"
                    class="error--text mt-1"
                    title="Delete"
                    @click.stop="deleteOrg(item)"
                    size="32"
                  >
                    icons8-close-window
                  </v-icon>
                  <v-icon
                    class="mt-1"
                    @click.stop="starItem(item)"
                    :class="item.isStarred ? 'gold--text' : 'grey--text'"
                    size="32"
                  >
                    icons8-rating
                  </v-icon>
                  <div
                    class="d-flex align-center"
                    @click.stop="openMatches(item)"
                    style="cursor: pointer"
                  >
                    <v-icon :class="item.matchCount > 0 ? 'success--text' : 'grey--text'" size="36"
                      >icons8-match</v-icon
                    >
                    <span :class="item.matchCount > 0 ? 'success--text' : 'grey--text'">
                      {{ item.matchCount }}
                    </span>
                  </div>
                </div>
              </template>
              <div class="my-2 grey lighten-3" style="height: 3px !important"></div>

              <div class="px-3 text-body-1 grey--text text--darken-4" @click="editOrg(item)">
                <strong class="grey--text">Match Profile</strong><br />
                <strong class="font-italic">Sectors</strong><br />
                <div
                  v-if="item?.sectors && item.sectors.some((sector) => lookupValue(sector).length)"
                  class="chip-container"
                >
                  <v-chip
                    v-for="(sector, index) in item.sectors.filter(
                      (sector) => lookupValue(sector).length
                    )"
                    :key="index"
                    class="mr-1 my-1 secondary lighten-2 primary--text"
                  >
                    {{ lookupValue(sector) }}
                  </v-chip>
                </div>
                <div v-else>-<br /></div>

                <strong class="font-italic">Locations</strong><br />
                <div v-if="item?.locations" class="scroll-container">
                  <v-chip
                    v-for="(location, index) in item.locations"
                    :key="index"
                    class="mr-1 my-1 secondary lighten-2 primary--text"
                  >
                    {{ lookupValue(location) }}
                  </v-chip>
                </div>
                <div v-else>-<br /></div>
              </div>
            </BasicCard>
          </div>
        </div>
      </v-col>
      <!-- Filter Drawer -->
      <v-navigation-drawer
        width="400px"
        class="px-5 pb-5 grey lighten-3"
        v-model="filterDrawer"
        style="z-index: 999"
        absolute
        clipped
        right
      >
        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center pt-4">
              <div class="text-h5 secondary--text">Filters</div>
              <v-spacer />
              <v-btn icon depressed @click="filterDrawer = false"
                ><v-icon>icons8-close</v-icon></v-btn
              >
            </div>
            Use the filters below to narrow your search.
          </v-col>

          <!-- Search -->
          <v-col cols="12">
            <!-- Status -->
            <div class="flex-grow-1">
              <div class="d-flex align-center pb-4">
                <SearchField
                  v-if="$vuetify.breakpoint.width <= 1500 && filterDrawer"
                  label="Search Clients"
                  v-model="search"
                  :dense="true"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <!-- Status -->
            <div class="flex-grow-1">
              <div class="my-2 text-h6 primary--text">Engaged</div>
              <div class="d-flex align-center pb-4">
                <DropdownMultiple :items="orgEngaged" v-model="filter.engaged" label="Engaged" />
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <!-- SHOW DELETED -->
            <div class="flex-grow-1">
              <!-- <div class="my-2 text-h6 primary--text">Engaged</div> -->
              <div class="d-flex align-center pb-4 pl-2">
                <v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
                <div class="font-weight-bold secondary--text">Show Deleted</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-navigation-drawer>

      <!-- Confirmation Box -->
      <ConfirmBox
        headerclass="primary"
        footerclass="grey lighten-2"
        :value="confirmBox"
        v-on:close="confirmBox = false"
        style="z-index: 999"
      >
        <template v-slot:header>
          <div class="full-width d-flex align-center">
            <div>Confirm</div>
            <v-spacer />
            <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
          </div>
        </template>
        <p>Please confirm you want to delete this Client</p>
        <strong>{{ org.name }}</strong
        ><br />
        <template v-slot:footer>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
              <AppButton buttonclass="warning" @click.native="confirmDeleteOrg">Confirm</AppButton>
            </v-col>
          </v-row>
        </template>
      </ConfirmBox>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ClientsView",
  // data
  data: () => ({
    loading: false,
    drawer: false,
    table: false,
    filterDrawer: false,
    confirmBox: false,
    permenantDelete: false,
    search: "",
    delayedSearch: "",

    // enable delete
    enableDelete: false,

    //stat elements
    isStarred: false,
    stat: {},
    seeFavourites: false,
    starredIdsArray: [],
    idsArr: [],

    // filter elements
    filter: {
      status: ["approved", "pending"],
      showDeleted: false,
      engaged: [],
    },
    tableData: [],
    itemsTotal: 0,
    tableHeaders: [
      {
        text: "Status",
        value: "status",
        align: "start",
        hidden: true,
        sortable: false,
        shrunk: true,
        smallDevice: false,
      },
      {
        text: "ID",
        value: "id",
        align: "start",
        hidden: true,
        sortable: false,
        shrunk: true,
        smallDevice: true,
      },
      {
        text: "First Name",
        value: "firstName",
        align: "start",
        hidden: false,
        sortable: false,
        shrunk: true,
        smallDevice: true,
      },
      {
        text: "Last Name",
        value: "lastName",
        align: "start",
        hidden: false,
        sortable: false,
        shrunk: true,
        smallDevice: true,
      },
      {
        text: "Company",
        value: "companyName",
        align: "start",
        hidden: false,
        sortable: false,
        shrunk: true,
        smallDevice: true,
      },
      {
        text: "Matches",
        value: "matchCount",
        align: "center",
        hidden: false,
        sortable: false,
        shrunk: false,
        smallDevice: true,
      },

      {
        text: "Sectors",
        value: "sectors",
        align: "start",
        hidden: true,
        sortable: false,
        shrunk: false,
        smallDevice: false,
      },
      {
        text: "Locations",
        value: "locations",
        align: "start",
        hidden: true,
        sortable: false,
        shrunk: false,
        smallDevice: false,
      },
      {
        text: "deleted",
        value: "deleted",
        align: "start",
        hidden: true,
        sortable: false,
        shrunk: false,
        smallDevice: true,
      },
      {
        text: "",
        value: "action",
        align: "center",
        hidden: false,
        sortable: false,
        width: "110px",
        shrunk: true,
        smallDevice: true,
      },
    ],
    tableOptions: {
      page: 1,
      itemsPerPage: 12,
      sortBy: ["name"],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
    },
    validate: {},
    org: {},
    users: [],

    // lookup data
    orgEngaged: [],
    countries: [],
    orgTypes: [],
    orgStatus: [],
    orgGroups: [],
    orgLevels: [],
    orgLanguages: [],
    action: "Add",
    showInfo: false,
    lookups: [],
  }),

  // computed
  computed: {
    ...mapGetters({
      GET_currentUserProfile: "GET_currentUserProfile",
    }),
    filteredHeaders() {
      let headers = this.tableHeaders.filter((header) => !header.hidden);
      if (this.drawer) {
        headers = headers.filter((header) => header.shrunk);
      }
      if (this.isSmallDevice()) {
        headers = headers.filter((header) => header.smallDevice);
      }
      return headers;
    },

    filterQuery() {
      let query = "";
      if (
        this.delayedSearch !== "" &&
        this.delayedSearch !== null &&
        this.delayedSearch !== undefined &&
        this.delayedSearch.length > 1
      ) {
        query += `${this.delayedSearch.trim()}* `;
      }
      if (this.filter.showDeleted) {
        query += `@deleted:{true}`;
      } else {
        query += `@deleted:{false}`;
      }
      // Filter Engaged
      if (JSON.stringify(this.filter.engaged) !== "[]") {
        query += ` @engaged:{${this.filter.engaged.join("|")}}`;
      }

      // Filter starred
      if (this.seeFavourites) {
        // console.log("this.idsArr", this.idsArr);
        return (query += ` @id:${this.idsArr.join("|")}`);
      }
      return query;
    },
    itemsFrom() {
      return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
    },
    itemsTo() {
      if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
        return this.itemsTotal;
      } else {
        return this.tableOptions.page * this.tableOptions.itemsPerPage;
      }
    },
    itemsCount() {
      return this.tableOptions.itemsPerPage;
    },
  },
  // watch
  watch: {
    search: {
      handler(newSearch) {
        // Clear any previous timeout
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }

        // Set a new timeout to delay the update
        this.searchTimeout = setTimeout(() => {
          this.delayedSearch = newSearch;
        }, 1000); // Adjust the delay time as needed (e.g., 1000 milliseconds = 1 second)
      },
      immediate: false,
    },

    filterQuery: {
      handler() {
        this.tableOptions.page = 1;
        this.getTableData();
      },
      deep: true,
    },
    tableOptions: {
      handler() {
        this.getTableData();
      },
      deep: true,
    },
  },
  // methods
  methods: {
    // checks size and sets the columns on the table
    isSmallDevice() {
      return window.innerWidth < 880;
    },
    isRecent(dateString) {
      if (!dateString) return false;

      const date = new Date(dateString);
      const now = new Date();
      const differenceInTime = now.getTime() - date.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      return differenceInDays <= 7;
    },
    // Star Items
    async starItem(item) {
      try {
        if (item.isStarred) {
          // if item is already starred then remove it
          let searchQuery = `@createdUserId:{${this.GET_currentUserProfile.entityId}} @linkedId:{${item.id}} @deleted:{false}`;
          let contentResult = await this.REDIS_searchFor("stat", "", "", "", "", searchQuery);
          let statToRemove = contentResult.data.documents[0];
          await this.REDIS_delete("stat", statToRemove.id, {
            modifiedUserId: this.GET_currentUserProfile.entityId,
            modifiedDateTime: this.GET_currentUserProfile.entityId,
            deletedUserId: this.GET_currentUserProfile.entityId,
            deletedDateTime: this.GET_currentUserProfile.entityId,
            deleted: true,
          });
          await this.getStatData();
        } else {
          // add item to starred
          this.stat = { ...this.$schema.stat };
          // data for db
          this.stat.id = this.MIX_generateId();
          this.stat.type = "star";
          this.stat.category = "clients";
          this.stat.subcategory = "";
          this.stat.linkedId = item.id;
          this.stat.createdUserId = this.GET_currentUserProfile.entityId;
          this.stat.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
          this.stat.modifiedUserId = this.GET_currentUserProfile.entityId;
          this.stat.modifiedDateTime = this.GET_currentUserProfile.entityId;
          await this.REDIS_createWithId("stat", this.stat, this.stat.id);
          await this.getStatData();
        }
      } catch (error) {
        this.MIX_alertBox({
          show: true,
          message: "Error Saving Starred Item",
          color: "error",
          timeout: "4000",
        });
        console.error("error", error);
      } finally {
        // starred and table data
        this.getTableData();
      }
    },
    // Get starred content
    async getStatData() {
      let searchQuery = `@type:{star} @deleted:{false} @category:{clients} @createdUserId:{${this.GET_currentUserProfile.entityId}} `;
      let statData = await this.REDIS_searchFor(
        "stat",
        "",
        "",
        "createdDateTime",
        "desc",
        searchQuery
      );
      this.starredIdsArray = statData.data.documents.map((item) => item.linkedId);
      // console.log("this.starredIdsArray=", this.starredIdsArray);
      if (this.starredIdsArray.length > 0 && this.starredIdsArray !== undefined) {
        this.idsArr = this.starredIdsArray.filter((item) => typeof item === "string");
      } else {
        this.idsArr = [];
      }
    },
    // Get Table Data
    async getTableData() {
      try {
        this.loading = true;

        // Step 1: Fetch organizations (initial list)
        let dataResult = await this.REDIS_searchByOrgs(
          this.itemsFrom,
          this.itemsCount,
          this.filterQuery
        );
        let tableData = dataResult?.data?.data || []; // Ensure tableData is always an array
        this.itemsTotal = 12;

        // Step 1.5: Apply client-side filtering if enabled
        if (this.useClientSideFiltering && this.delayedSearch && this.delayedSearch.trim()) {
          const searchTerm = this.delayedSearch.trim().toLowerCase();
          tableData = tableData.filter(
            (item) =>
              (item.firstName && item.firstName.toLowerCase().includes(searchTerm)) ||
              (item.lastName && item.lastName.toLowerCase().includes(searchTerm)) ||
              (item.companyName && item.companyName.toLowerCase().includes(searchTerm))
          );
          // Update total count for pagination when filtering client-side
          this.itemsTotal = tableData.length;
        }

        // Step 2: Calculate accurate match counts for each organization
        // for (let i = 0; i < tableData.length; i++) {
        //   let org = tableData[i];

        //   // Explicitly include only pending and accepted matches
        //   let searchQuery = `@deleted:{false} @orgId:{${org.id}} @status:{accepted|pending}`;

        //   // Ensure we are NOT including rejected matches
        //   searchQuery += ` -@status:{rejected}`; // Add this if needed for your implementation

        //   let matchResult = await this.REDIS_searchFor("match", "0", "0", "", "", searchQuery);
        //   org.matchCount = matchResult.data.total;
        // }

        // Step 3: Mark starred items
        if (Array.isArray(this.starredIdsArray) && this.starredIdsArray.length > 0) {
          tableData = tableData.map((org) => ({
            ...org,
            isStarred: this.starredIdsArray.includes(org.id),
          }));
        }

        // Step 4: Update state
        this.tableData = tableData.slice(0, 12);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    // Add Org
    addOrg() {
      this.MIX_go("/client/new");
    },
    // Next page of the table
    next() {
      if (this.tableOptions.page * this.tableOptions.itemsPerPage < this.itemsTotal) {
        this.tableOptions.page++;
      }
    },
    // Previous page of the table
    previous() {
      if (this.tableOptions.page > 1) {
        this.tableOptions.page--;
      }
    },
    // Edit Org
    editOrg(org) {
      this.MIX_go({
        name: "ClientView",
        params: { id: org.id, formMode: "read", orgTab: "details" },
      });
    },
    // Open Matches
    openMatches(item) {
      this.MIX_go({
        name: "ClientView",
        params: { id: item.id, formMode: "read", orgTab: "profile" },
      });
    },

    // Delete Org
    async deleteOrg(org) {
      let clientInfo = await this.REDIS_read("org", org.id);
      clientInfo = clientInfo.data;
      this.org = { ...clientInfo };
      this.action = "Delete";
      this.confirmBox = true;
    },

    // Restore Org
    async restoreOrg(org) {
      try {
        this.loading = true;
        this.org = { ...org };
        this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
        this.org.modifiedOrgId = this.GET_currentUserProfile.orgId;
        this.org.deleted = false;
        this.org.deletedUserId = null;
        this.org.deletedOrgId = null;
        this.org.deletedDateTime = null;
        await this.REDIS_update("org", this.org.entityId, this.org);
        this.loading = false;
        this.confirmBox = false;
        this.permenantDelete = false;
        this.MIX_alertBox({ show: true, message: "Restored", color: "success", timeout: "2000" });
        this.getTableData();
      } catch (error) {
        this.MIX_alertBox({
          show: true,
          message: "Error Restoring",
          color: "error",
          timeout: "4000",
        });
        console.error(error);
      }
    },
    // Confirm Delete Org
    async confirmDeleteOrg() {
      try {
        this.loading = true;
        this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
        this.org.modifiedOrgId = this.GET_currentUserProfile.orgId;
        this.org.deleted = true;
        this.org.deletedUserId = this.GET_currentUserProfile.entityId;
        this.org.deletedOrgId = this.GET_currentUserProfile.orgId;
        this.org.deletedDateTime = this.MIX_formatDateTimeNow("toISOString");
        // NOTE uncomment line below to permanently delete a client from REDIS
        // await this.REDIS_destroy("org", this.org.entityId);
        await this.REDIS_update("org", this.org.entityId, this.org);
        this.loading = false;
        this.confirmBox = false;
        // this.permenantDelete = false;
        this.MIX_alertBox({ show: true, message: "Deleted", color: "success", timeout: "2000" });
        this.getTableData();
      } catch (error) {
        this.MIX_alertBox({
          show: true,
          message: "Error Deleting",
          color: "error",
          timeout: "4000",
        });
        console.error(error);
      }
    },

    // Initialise
    async initialise() {
      this.loading = true;
      let lookupResult = await this.REDIS_searchFor(
        "lookup",
        "",
        "",
        "text",
        "asc",
        "@deleted:{false} @type:{county|country|city|region|organisation_type|country|organisation_status|organisation_engagement|sector}"
      );
      this.lookups = lookupResult.data.documents;
      this.orgEngaged = this.lookups.filter((item) => item.type === "organisation_engagement");
      this.orgTypes = this.lookups.filter((item) => item.type === "organisation_type");
      this.orgStatus = this.lookups.filter((item) => item.type === "organisation_status");
      this.countries = this.lookups.filter((item) => item.type === "country");
      // starred and table data
      await this.getStatData();
      this.getTableData();
      // this.loading = false;
    },
    // Update Table Options
    updateTableOptions(options) {
      this.tableOptions = options;
    },
    // Get Users
    async getUsers() {
      try {
        this.loading = true;
        let query = `@deleted:{false}`;
        let usersResult = await this.REDIS_searchFor("user", "", "", "firstName", "asc", query);
        let users = usersResult.data.documents;
        for (let i = 0; i < users.length; i++) {
          const user = users[i];
          user.fullName = user.firstName + " " + user.lastName + " (" + user.email + ")";
        }
        this.users = users;
        // console.log("this.users", JSON.stringify(this.users, null, 2));
        this.loading = false;
      } catch (error) {
        this.MIX_alertBox({
          show: true,
          message: "Error Getting Users",
          color: "error",
          timeout: "4000",
        });
        console.error(error);
      }
    },
    toggleFilters() {
      if (this.drawer) {
        this.drawer = false;
      }
      this.filterDrawer = !this.filterDrawer;
    },
    lookupValue(value) {
      if (value !== "") {
        let lookup = this.lookups.find((item) => item.value === value);
        if (lookup !== undefined) {
          return lookup.text;
        } else {
          return "";
        }
      }
    },
  },

  // * CREATED
  created() {
    this.initialise();
  },
};
</script>

<style scoped>
.leftside-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
  z-index: 99; /* Specify a stack order in case you're using a different order for other elements */
}

.loader-overlay {
  position: fixed;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex {
  gap: 15px;
}

.business-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  gap: 15px; /* Space between cards */
  justify-content: center;
  padding: 10px;
}

/* Individual business cards */
.business-cards {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

/* Ensures uniform card height */
.business-card-component {
  height: 320px; /* Set a fixed height for all cards */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
}

/* Limits the match profile section height */
.match-profile {
  flex-grow: 1; /* Allows it to expand while keeping cards even */
  overflow-y: auto; /* Ensures scrolling if content exceeds space */
}

/* Ensures overflow content remains scrollable without breaking layout */
.scroll-container {
  max-height: 80px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

/* Icons remain properly sized */
.v-icon {
  flex-shrink: 0;
  min-width: 24px;
  max-width: 24px;
}

/* Prevent chip text overflow */
.v-chip {
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 12px;
}
</style>
